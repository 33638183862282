import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import extLinkImg from "../../assets/files/inline-images/externallinkiconsm.jpg";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import rehypeRaw from "rehype-raw";
//import AgentPortalComingSoonPDF from "../../assets/files/Agent_Portal_Coming_Soon.pdf";

const Footer = () => {
  const [content, isLoading] = useContent("348s2bKVOcgWHswBwZunE6");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div>
      <div className="col-xs-12 p-0 bottom-links">
        <div className="py-5">
          <div className="region region-footer">
            <nav
              role="navigation"
              aria-labelledby="block-npamedicare-footer-menu"
              id="block-npamedicare-footer"
            >
              <h2
                className="visually-hidden"
                id="block-npamedicare-footer-menu"
              >
                Footer menu
              </h2>

              <ul
                block="block-npamedicare-footer"
                className="menu menu--footer nav"
              >
                <li className="first">
                  <Link to="/About">About us</Link>
                </li>
                <li>
                  <Link to="/TermsOfUse">Terms of use </Link>
                </li>
                <li>
                  <Link to="/Privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/Learn/MedicareBlueFWA">
                    Fraud, waste and abuse
                  </Link>
                </li>
                {/* <li>
                  <a href="https://assets.ctfassets.net/xfdtsvzh7hmn/1vPmk1K2uX41ls9Oo5BEuS/8f9cc450f8ba9ae9c0bbd77108b54bd3/S5743_Notice_of_Rights_pre_enroll-508.pdf">
                    Nondiscrimination and accessibility (PDF)
                  </a>
                </li> */}
                <li className="last">
                  {/* <a href="https://agentportal-clearstone.tmghealth.com/portal/" target="_blank">
                    Agent portal <img src={extLinkImg} alt="external link" />
                  </a> */}
                  {/* <a href={AgentPortalComingSoonPDF} target="_blank">
                    Agent portal <img src={extLinkImg} alt="external link" />
                  </a> */}
                  <Link to="/AgentPortal">
                    Agent portal <img src={extLinkImg} alt="external link" />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="col-xs-12 p-0 npa-gradient">
        <div className="container py-5">
          <div className="region region-content-bottom">
            <section
              id="block-bottom-text"
              className="block block-block-content block-block-content49e493e0-cf61-41ac-867e-d23adf316aac clearfix"
            >
              <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={content.copy}
                />

                <p>© {moment().format("YYYY")} All rights reserved.</p>
              </div>
            </section>

            <section
              id="block-lastupdatedblock"
              className="block block-clearstone block-last-updated-block clearfix"
            >
              {/* Last Updated: January 1, 2022 */}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
