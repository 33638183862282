import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_ENROLLMENT_API_URL;

export async function submitEnrollment(enrollment) {
  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(enrollment),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
