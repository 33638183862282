import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_CONTACT_API_URL;

export async function submitContact(contact) {
  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(contact),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
