import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import ReactGA from "react-ga";
import "./assets/themes/custom/npamedicare/css/bootstrap.min.css";
import "./assets/themes/custom/npamedicare/css/style.css";
import "./assets/themes/custom/npamedicare/css/npasellsheet.css";
import "./assets/themes/custom/npamedicare/css/bootstrap.css";
import "./assets/themes/custom/npamedicare/css/datepicker.css";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import PaymentChangeWithRouter from "./components/Change/PaymentChange";

// import MBRxChange2022WithRouter from "./components/Change/2022/MbrxChange2022";
import MBRxChange2023WithRouter from "./components/Change/2023/MbrxChange2023";
import MBRxChange2024WithRouter from "./components/Change/2024/MbrxChange2024";
import DemographicChangeWithRouter from "./components/Change/DemographicChange";
import AboutStarRatings from "./components/Compare/AboutStarRatings";
import ComparePlans from "./components/Compare/ComparePlans";
import OtherMedicarePlans from "./components/Compare/OtherMedicarePlans";
// import PlanCompare2022 from "./components/Compare/PlanCompare2022";
import PlanCompare2023 from "./components/Compare/PlanCompare2023";
import PlanCompare2024 from "./components/Compare/PlanCompare2024";

import ContactEMTMHealthCarePro from "./components/Contact/ContactEMTMHealthCarePro";
import ContactUs from "./components/Contact/ContactUs";
import Contact from "./components/Contact/Contact";
import ContactConfirmation from "./components/Contact/ContactConfirmation";
import Covid19 from "./components/Covid/Covid19";
import IndividualPlanDocs2024 from "./components/Documents/IndividualPlanDocs2024";
import IndividualPlanDocs2023 from "./components/Documents/IndividualPlanDocs2023";
import IndividualPlanDocs2022 from "./components/Documents/IndividualPlanDocs2022";
import IndividualPlanDocs2021 from "./components/Documents/IndividualPlanDocs2021";
import DrugPricingTool2022 from "./components/DrugPricingTool/DrugPricingTool2022";
import DrugPricingTool2021 from "./components/DrugPricingTool/DrugPricingTool2021";
import EmployerGroupDocs2024 from "./components/EmployerGroupPlans/EmployerGroupDocs2024";
import EmployerGroupDocs2023 from "./components/EmployerGroupPlans/EmployerGroupDocs2023";
import EmployerGroupDocs2022 from "./components/EmployerGroupPlans/EmployerGroupDocs2022";
import EmployerGroupDocs2021 from "./components/EmployerGroupPlans/EmployerGroupDocs2021";
import FWA from "./components/EmployerGroupPlans/FWA";
import GroupContactInfo from "./components/EmployerGroupPlans/GroupContactInfo";
import GroupCommunicationTimeline from "./components/EmployerGroupPlans/GroupCommunicationTimeline";
import GroupPlanResearchTools from "./components/EmployerGroupPlans/GroupPlanResearchTools";
import GroupResources from "./components/EmployerGroupPlans/GroupResources";
import PartDPlansEmployerAndUnionGroups from "./components/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups";

// import ChangePlan2022 from "./components/Enroll/2022/ChangePlan2022";
import ChangePlan2023 from "./components/Enroll/2023/ChangePlan2023";
import ChangePlan2024 from "./components/Enroll/2024/ChangePlan2024";

import S5743NoticeOfRightsPreEnroll508 from "./components/Files/S5743NoticeOfRightsPreEnroll508";
import GroupBenefits from "./components/EmployerGroupPlans/GroupBenefits";
import MedicareBlueFWA from "./components/Learn/MedicareBlueFWA";
import CoverageDeterminations from "./components/Members/CoverageDeterminations";
import DrugClaims from "./components/Members/DrugClaims";
import DrugInfo from "./components/Members/DrugInfo";
import RightsAndResponsibilities from "./components/Members/RightsAndResponsibilities";
import EMTMProviders from "./components/Members/EMTMProviders";
import EMTMProgram from "./components/Members/EMTMProgram";
import MailOrderPrescriptions from "./components/Members/MailOrderPrescriptions";
import MedicationTherapyManagementProgram from "./components/Members/MedicationTherapyManagementProgram";
import MemberDiscounts from "./components/Members/MemberDiscounts";
import Members from "./components/Members/Members";
import OrderMemberIdCard from "./components/Members/OrderMemberIdCard";
import PaymentInfo from "./components/Members/PaymentInfo";
import PharmacyInfo from "./components/Members/PharmacyInfo";
import PlanTransitionSupply from "./components/Members/PlanTransitionSupply";
import SubmitClaim from "./components/Members/SubmitClaim";
import CommunicationTimeline from "./components/PartDBasics/CommunicationTimeline";
import EligibilityRequirements from "./components/PartDBasics/EligibilityRequirements";
import FAQ from "./components/PartDBasics/FAQ";
import FinancialAssistance from "./components/PartDBasics/FinancialAssistance";
import Glossary from "./components/PartDBasics/Glossary";
import PartDBasics from "./components/PartDBasics/PartDBasics";
import WhenToEnroll from "./components/PartDBasics/WhenToEnroll";
import FindMyPharmacyGroup2022 from "./components/Pharmacy/FindMyPharmacyGroup2022";
import FindMyPharmacyPremier2022 from "./components/Pharmacy/FindMyPharmacyPremier2022";
import FindMyPharmacyPremier2021 from "./components/Pharmacy/FindMyPharmacyPremier2021";
import FindMyPharmacyStandard2022 from "./components/Pharmacy/FindMyPharmacyStandard2022";
import FindMyPharmacyStandard2021 from "./components/Pharmacy/FindMyPharmacyStandard2021";
import FindMyPharmacyGroup2021 from "./components/Pharmacy/FindMyPharmacyGroup2021";
import PharmacyTools from "./components/Pharmacy/PharmacyTools";
import PrivacyPolicy from "./components/PrivacyTerms/PrivacyPolicy";
import TermsOfUse from "./components/PrivacyTerms/TermsOfUse";
import HeaderWithRouter from "./components/Common/Header";
import Footer from "./components/Common/Footer";
import {
  StateMachineProvider,
  createStore,
  useStateMachine,
} from "little-state-machine";

import enrollData from "./state/data/enrollData";
import Enroll from "./components/Enroll/Enroll";

import validationData from "./state/data/validationData";

// import EnrollNow2022 from "./components/Enroll/2022/EnrollNow2022";
import EnrollNow2023 from "./components/Enroll/2023/EnrollNow2023";
import EnrollNow2024 from "./components/Enroll/2024/EnrollNow2024";

// import MBRx2022WithRouter from "./components/Enroll/2022/MBRxEnroll2022";
import MBRx2023WithRouter from "./components/Enroll/2023/MBRxEnroll2023";
import MBRx2024WithRouter from "./components/Enroll/2024/MBRxEnroll2024";

import ScrollToTop from "./components/Common/ScrollToTop";

import PageNotFound from "./components/PageNotFound";

import BackToTop from "./components/Common/BackToTop";
import SearchResultsList from "./components/Search/SearchResultsList";

import ExternalLinkHandler from "./components/Common/ExternalLinkHandler";
import AgentPortal from "./components/Common/AgentPortal";

import updateValidationData from "./state/actions/updateValidationData";

export default function App() {
  ReactGA.initialize("UA-31493464-1");

  createStore({
    enrollData,
    validationData
  });

  function resizeText(multiplier) {
    if (document.body.style.fontSize === "") {
      document.body.style.fontSize = "18px";
    }
    switch (multiplier) {
      case 1:
        document.body.style.fontSize = "18px";
        break;
      case 1.5:
        document.body.style.fontSize = "24px";
        break;
      case 2:
        document.body.style.fontSize = "36px";
        break;
      default:
        document.body.style.fontSize = "18px";
        break;
    }
    // document.body.style.fontSize =
    //   parseFloat(document.body.style.fontSize) + multiplier * 1.0 + "em";
  }

  // const [displayBankValidationMessage, setDisplayBankValidationMessage] =
  //   useState(false);
  // const [disableEFTOption, setDisableEFTOption] = useState(false);
  // const [counter, setCounter] = useState(1);

  // const { state, actions } = useStateMachine({
  //   updateValidationData
  // });

  // // reset validationCounter to 0 on application load
  // useEffect(() => () => {
  //     actions.updateValidationData({ validationCounter: 0 });
  //   },[]);

  return (
    <StateMachineProvider>
      <Router>
        <HeaderWithRouter
          resizeText={resizeText}
          // handleSearch={handleSearch}
          // searchResults={searchResults}
          // isLoading={isLoading}
        />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/index.html"><Redirect to="/" /></Route>
          <Route path="/About" exact component={About} />
          <Route
            path="/mbrx-change-payment-option"
            exact
            component={() => (
              <PaymentChangeWithRouter
                //counter={counter}
                //setCounter={setCounter}
                //displayBankValidationMessage={displayBankValidationMessage}
                //setDisplayBankValidationMessage={
                //  setDisplayBankValidationMessage
                //}
                //disableEFTOption={disableEFTOption}
                //setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route
            path="/ChangePayment"
            exact
            component={PaymentChangeWithRouter}
          />
          <Route
            path="/change-payment-option"
            exact
            component={() => (
              <PaymentChangeWithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          {/* <Route
            path="/Change/MBRxChange2021"
            exact
            component={MBRxChange2021}
          /> */}
          {/* <Route
            path="/Change/MBRxChange2022"
            exact
            component={() => (
              <MBRxChange2022WithRouter
                counter={counter}
                setCounter={setCounter}
                displayBankValidationMessage={displayBankValidationMessage}
                setDisplayBankValidationMessage={
                  setDisplayBankValidationMessage
                }
                disableEFTOption={disableEFTOption}
                setDisableEFTOption={setDisableEFTOption}
              />
            )}
          /> */}
          <Route
            path="/Change/MBRxChange2022"
            exact
            component={() => (
              <MBRxChange2023WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route
            path="/Change/MBRxChange2023"
            exact
            component={() => (
              <MBRxChange2024WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route
            path="/Change/MBRxChange2024"
            exact
            component={() => (
              <MBRxChange2024WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route
            path="/mbrx-update-contact-information"
            exact
            component={DemographicChangeWithRouter}
          />
          <Route
            path="/UpdateContact"
            exact
            component={DemographicChangeWithRouter}
          />
          <Route
            path="/compare-plans/about-star-ratings"
            exact
            component={AboutStarRatings}
          />
          <Route path="/StarRatings" exact component={AboutStarRatings} />
          <Route path="/compare-plans" exact component={ComparePlans} />
          <Route path="/Compare" exact component={ComparePlans} />
          <Route
            path="/Compare/OtherMedicarePlans"
            exact
            component={OtherMedicarePlans}
          />
          <Route
            path="/Compare/PlanCompare2024"
            exact
            component={PlanCompare2024}
          />
          <Redirect
            from="/Compare/PlanCompare2023"
            to="/Compare/PlanCompare2024"
          />
          {/* <Route
            path="/Compare/PlanCompare2023"
            exact
            component={PlanCompare2024}
          /> */}
          <Redirect
            from="/Compare/PlanCompare2022"
            to="/Compare/PlanCompare2023"
          />
          {/* <Route
            path="/Compare/PlanCompare2022"
            exact
            component={PlanCompare2023}
          /> */}
          {/* <Route
            path="/Compare/PlanCompare2022"
            exact
            component={PlanCompare2022}
          /> */}
          {/* <Route
            path="/Compare/PlanCompare2021"
            exact
            component={PlanCompare2021}
          /> */}
          <Route
            path="/Contact/ContactEMTMHealthCarePro"
            exact
            component={ContactEMTMHealthCarePro}
          />
          <Route
            path="/Contact/contact-form-online"
            exact
            component={ContactUs}
          />
          <Route
            path="/contact-confirmation"
            exact
            component={ContactConfirmation}
          />
          <Route path="/contact/medicareblue-rx" exact component={Contact} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/Covid/Covid19" exact component={Covid19} />
          <Route path="/Covid" exact component={Covid19} />

          <Route
            path="/2024Documents"
            exact
            component={IndividualPlanDocs2024}
          />
          <Route
            path="/Documents/IndividualPlanDocs2024"
            exact
            component={IndividualPlanDocs2024}
          />

          <Redirect from="/2023Documents" to="/2024Documents" />
          {/* <Route
            path="/2023Documents"
            exact
            component={IndividualPlanDocs2023}
          /> */}

          <Redirect
            from="/Documents/IndividualPlanDocs2023"
            to="/Documents/IndividualPlanDocs2024"
          />
          {/* <Route
            path="/Documents/IndividualPlanDocs2023"
            exact
            component={IndividualPlanDocs2023}
          /> */}

          <Redirect
            from="/Documents/IndividualPlanDocs2022"
            to="/Documents/IndividualPlanDocs2023"
          />
          {/* <Route
            path="/Documents/IndividualPlanDocs2022"
            exact
            component={IndividualPlanDocs2022}
          /> */}
          <Route path="/Documents" exact component={IndividualPlanDocs2022} />
          <Route
            path="/Documents/IndividualPlanDocs2021"
            exact
            component={IndividualPlanDocs2021}
          />
          <Redirect
            from="/DrugPricingTool/DrugPricingTool2022"
            to="/pharmacy-tools-landing"
          />
          {/* <Route
            path="/DrugPricingTool/DrugPricingTool2022"
            exact
            component={PharmacyTools}
            // component={DrugPricingTool2022}  // 12/31/22 Redirect 2022 Drug Pricing to PharmacyTools
          /> */}
          <Route
            path="/DrugPricingTool/DrugPricingTool2021"
            exact
            component={DrugPricingTool2021}
          />
          <Route
            path="/EmployerGroupPlans/EmployerGroupDocs2024"
            exact
            component={EmployerGroupDocs2024}
          />
          <Route
            path="/2024GroupDocuments"
            exact
            component={EmployerGroupDocs2024}
          />
          <Redirect
            from="/EmployerGroupPlans/EmployerGroupDocs2023"
            to="/EmployerGroupPlans/EmployerGroupDocs2024"
          />
          {/* <Route
            path="/EmployerGroupPlans/EmployerGroupDocs2023"
            exact
            component={EmployerGroupDocs2023}
          /> */}

          <Redirect from="/2023GroupDocuments" to="/2024GroupDocuments" />
          {/* <Route
            path="/2023GroupDocuments"
            exact
            component={EmployerGroupDocs2023}
          /> */}

          <Redirect
            from="/EmployerGroupPlans/EmployerGroupDocs2022"
            to="/EmployerGroupPlans/EmployerGroupDocs2023"
          />
          {/* <Route
            path="/EmployerGroupPlans/EmployerGroupDocs2022"
            exact            
            component={EmployerGroupDocs2022}
          /> */}
          <Route
            path="/GroupDocuments"
            exact
            component={EmployerGroupDocs2022}
          />
          <Route
            path="/EmployerGroupPlans/EmployerGroupDocs2021"
            exact
            component={EmployerGroupDocs2021}
          />
          <Route path="/EmployerGroupPlans/FWA" exact component={FWA} />
          <Route
            path="/employer-group-plans/group-contact-information"
            exact
            component={GroupContactInfo}
          />
          <Route
            path="/EmployerGroupPlans/GroupCommunicationTimeline"
            exact
            component={GroupCommunicationTimeline}
          />
          <Route path="/GroupContact" exact component={GroupContactInfo} />
          <Route
            path="/EmployerGroupPlans/GroupPlanResearchTools"
            exact
            component={GroupPlanResearchTools}
          />
          <Route
            path="/GroupPharmacy"
            exact
            component={GroupPlanResearchTools}
          />
          <Route
            path="/EmployerGroupPlans/GroupResources"
            exact
            component={GroupResources}
          />
          <Route
            path="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups"
            exact
            component={PartDPlansEmployerAndUnionGroups}
          />
          {/* <Route
            path="/Enroll/2021-change-plan"
            exact
            component={ChangePlan2021}
          /> */}
          {/* <Route
            path="/Enroll/2022-change-plan"
            exact
            component={ChangePlan2022}
          /> */}
          <Redirect
            from="/Enroll/2022-change-plan"
            to="/Enroll/2023-change-plan"
          />
          {/* <Route
            path="/Enroll/2022-change-plan"
            exact
            component={ChangePlan2023}
          /> */}
          <Redirect
            from="/Enroll/2023-change-plan"
            to="/Enroll/2024-change-plan"
          />
          {/* <Route
            path="/Enroll/2023-change-plan"
            exact
            component={ChangePlan2024}
          /> */}
          <Route
            path="/Enroll/2024-change-plan"
            exact
            component={ChangePlan2024}
          />
          <Route
            path="/Files/S5743NoticeOfRightsPreEnroll508"
            exact
            component={S5743NoticeOfRightsPreEnroll508}
          />
          <Route path="/GroupBenefits" exact component={GroupBenefits} />
          <Route path="/GroupPlans" exact component={GroupBenefits} />
          <Route
            path="/Learn/MedicareBlueFWA"
            exact
            component={MedicareBlueFWA}
          />
          <Route
            path="/Members/coverage-determinations"
            exact
            component={CoverageDeterminations}
          />
          <Route path="/Members/DrugClaims" exact component={DrugClaims} />
          <Route path="/Members/drug-information" exact component={DrugInfo} />
          <Route path="/Members/emtm-program" exact component={EMTMProgram} />
          <Route
            path="/Members/emtm-providers"
            exact
            component={EMTMProviders}
          />
          <Route
            path="/Members/MailOrderPrescriptions"
            exact
            component={MailOrderPrescriptions}
          />
          <Route
            path="/Members/medication-therapy-management-program"
            exact
            component={MedicationTherapyManagementProgram}
          />
          <Route
            path="/Members/rights-and-responsibilities"
            exact
            component={RightsAndResponsibilities}
          />
          <Route
            path="/MTM"
            exact
            component={MedicationTherapyManagementProgram}
          />
          <Route
            path="/Members/MemberDiscounts"
            exact
            component={MemberDiscounts}
          />
          <Route path="/Members" exact component={Members} />
          <Route
            path="/Members/OrderMemberIdCard"
            exact
            component={OrderMemberIdCard}
          />
          <Route path="/Members/PaymentInfo" exact component={PaymentInfo} />
          <Route path="/Members/PharmacyInfo" exact component={PharmacyInfo} />
          <Route
            path="/Members/PlanTransitionSupply"
            exact
            component={PlanTransitionSupply}
          />
          <Route path="/Members/SubmitClaim" exact component={SubmitClaim} />
          <Route
            path="/PartDBasics/CommunicationTimeline"
            exact
            component={CommunicationTimeline}
          />
          <Route
            path="/PartDBasics/EligibilityRequirements"
            exact
            component={EligibilityRequirements}
          />
          <Route
            path="/part-d-basics/frequently-asked-questions"
            exact
            component={FAQ}
          />
          <Route
            path="/PartDBasics/FinancialAssistance"
            exact
            component={FinancialAssistance}
          />
          <Route path="/PartDBasics/Glossary" exact component={Glossary} />
          <Route path="/PartDBasics" exact component={PartDBasics} />
          <Route
            path="/PartDBasics/WhenToEnroll"
            exact
            component={WhenToEnroll}
          />
          <Route
            path="/GroupPharmacy2022"
            exact
            component={FindMyPharmacyGroup2022}
          />
          <Redirect
            from="/Pharmacy/FindMyPharmacyPremier2022"
            to="/pharmacy-tools-landing"
          />
          {/* <Route
            path="/Pharmacy/FindMyPharmacyPremier2022"
            exact
            component={FindMyPharmacyPremier2022}
          /> */}
          <Route
            path="/Pharmacy/FindMyPharmacyPremier2021"
            exact
            component={FindMyPharmacyPremier2021}
          />
          <Redirect
            from="/Pharmacy/FindMyPharmacyStandard2022"
            to="/pharmacy-tools-landing"
          />
          {/* <Route
            path="/Pharmacy/FindMyPharmacyStandard2022"
            exact            
            component={FindMyPharmacyStandard2022}
          /> */}
          <Route
            path="/Pharmacy/FindMyPharmacyStandard2021"
            exact
            component={FindMyPharmacyStandard2021}
          />
          <Route
            path="/GroupPharmacy2021"
            exact
            component={FindMyPharmacyGroup2021}
          />
          <Route
            path="/pharmacy-tools-landing"
            exact
            component={PharmacyTools}
          />
          <Route path="/PharmacyTools" exact component={PharmacyTools} />
          <Route path="/PlanTools" exact component={PharmacyTools} />
          <Route path="/Drugs" exact component={PharmacyTools} />
          <Route path="/Pharmacy" exact component={PharmacyTools} />
          <Route path="/Privacy" exact component={PrivacyPolicy} />
          <Route path="/TermsOfUse" exact component={TermsOfUse} />
          <Route path="/enroll-0" exact component={Enroll} />
          {/* <Route
            path="/enroll/2021-enroll-now"
            exact
            component={EnrollNow2021}
          />
          <Route path="/mbrx-enroll-2021" exact component={MBRx2021} /> */}

          {/* <Route
            path="/enroll/2022-enroll-now"
            exact
            component={EnrollNow2022}
          /> */}
          <Redirect
            from="/enroll/2022-enroll-now"
            to="/enroll/2023-enroll-now"
          />
          {/* <Route
            path="/enroll/2022-enroll-now"
            exact
            component={EnrollNow2023}
          /> */}
          <Redirect
            from="/enroll/2023-enroll-now"
            to="/enroll/2024-enroll-now"
          />
          {/* <Route
            path="/enroll/2023-enroll-now"
            exact
            component={EnrollNow2024}
          /> */}
          <Route
            path="/enroll/2024-enroll-now"
            exact
            component={EnrollNow2024}
          />

          {/* <Route
            path="/mbrx-enroll-2022"
            exact
            component={() => (
              <MBRx2022WithRouter
                counter={counter}
                setCounter={setCounter}
                displayBankValidationMessage={displayBankValidationMessage}
                setDisplayBankValidationMessage={
                  setDisplayBankValidationMessage
                }
                disableEFTOption={disableEFTOption}
                setDisableEFTOption={setDisableEFTOption}
              />
            )}
          /> */}
          <Route
            path="/mbrx-enroll-2022"
            exact
            component={() => (
              <MBRx2024WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route
            path="/mbrx-enroll-2023"
            exact
            component={() => (
              <MBRx2024WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route path="/AgentPortal" exact component={AgentPortal} />
          <Route
            path="/mbrx-enroll-2024"
            exact
            component={() => (
              <MBRx2024WithRouter
                // counter={counter}
                // setCounter={setCounter}
                // displayBankValidationMessage={displayBankValidationMessage}
                // setDisplayBankValidationMessage={
                //   setDisplayBankValidationMessage
                // }
                // disableEFTOption={disableEFTOption}
                // setDisableEFTOption={setDisableEFTOption}
              />
            )}
          />
          <Route path="/search" exact component={SearchResultsList} />
          <Route path="*" exact component={PageNotFound} />
        </Switch>
        <Footer />
        <ExternalLinkHandler />
      </Router>
      <BackToTop />
    </StateMachineProvider>
  );
}
