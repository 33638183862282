import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { Button } from "@material-ui/core";
import WhatsNext from "../../WhatsNext";
import moment from "moment";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import clearEnrollData from "../../../../state/actions/clearEnrollData";

const EnrollmentConfirmation_2023 = ({setBackButtonDisabled}) => {
  const { state, actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  // // disable back button
  // useEffect(
  //   () => {
  //     setBackButtonDisabled(true);
  //   }, []);

  useEffect(
    () => () => {
      actions.clearEnrollData();
      actions.updateEnrollData({ activeStep: 0, activeForm: "" });
    },
    []
  );

  const { register } = useForm({
    mode: "all",
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    let data = e.target.value;
    setEmail({ ...email, data });
  };

  const [emailSent, setEmailSent] = useState(false);

  const handleEmailSent = (e) => {
    e.preventDefault();
    setTimeout(() => setEmailSent(!emailSent), 3000); //<--setTimeout is to simulate the email submission. This will be replaced with call/response to API
  };

  const handlePrint = () => {
    window.print();
  };

  const getPaymentOptionText = () => {
    switch (state.enrollData.paymentoption) {
      case "Paper":
        return "Get a paper bill";
      case "EFT":
        return "Electronic funds transfer (EFT)";
      case "SSA":
        return "Automatic deduction from your Social Security benefit check";
      case "RRB":
        return "Automatic deduction from your Railroad Retirement Board (RRB) benefit check";
      default:
        return "";
    }
  };

  const getPreparerText = () => {
    switch (state.enrollData.preparer) {
      case "enrollee":
        return "I am the enrollee";
      case "authRep":
        return "I am the enrollee's authorized representative";
      case "assisted":
        return "I assisted the enrollee in filling out the form";
      case "agent":
        return "I am the enrollee's agent";
      default:
        return "";
    }
  };

  return (
    <>
      <div
        data-webform-key="step_5"
        data-drupal-selector="edit-step-5"
        className="js-form-wrapper form-wrapper form-group"
        data-msg-required="Step 5 is required."
        id="edit-step-5--2"
      >
        <div
          id="edit-step-5-title"
          className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                        webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                      "
        >
          <div className="webform-confirmation">
            <div className="webform-confirmation__message">
              <div className="row">
                <div className="col-sm-12">
                  <h3>
                    Your confirmation number is{" "}
                    {" " + state.enrollData.confirmationno}
                  </h3>
                  <p>
                    Congratulations, you have completed the online enrollment
                    for MedicareBlue Rx.
                  </p>
                  <p className="leftarrowheading">
                    Here's what to expect next.
                  </p>
                  <ul>
                    <li>
                      {" "}
                      You will receive several mailings over the next few weeks.
                    </li>
                    {/* begin post enroll call center script markup */}
                    <li>
                      <button
                        type="button"
                        onClick={handleOpen}
                        className="btn btn-link btn-anchor btn-lg"
                      >
                        See complete details on what to expect next
                      </button>
                      .
                    </li>

                    <WhatsNext
                      open={open}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      confNum={state.enrollData.confirmationno}
                    />
                    {/* begin post enroll call center script markup 2018 full path */}
                    <li>
                      If you have any questions or concerns about MedicareBlue
                      Rx{" "}
                      <a
                        href="/contact/medicareblue-rx"
                        data-tooltip="You will not lose your place on this confirmation page by clicking this link."
                        target="_blank"
                      >
                        contact customer service
                      </a>
                      .
                    </li>
                  </ul>
                  <p className="leftarrowheading">
                    Please write down the confirmation number above.
                  </p>
                  <ul>
                    <li>
                      You can also send it by email (other information on this
                      page will not be included in the email).
                    </li>
                    <li>You can print this page for your records.</li>
                  </ul>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <form
                    id="confirmation-form"
                    className="email-confirmation form-inline"
                    novalidate="novalidate"
                  >
                    <div className="form-group">
                      <input
                        {...register("confEmail", {
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email address.",
                          },
                        })}
                        onChange={handleEmailChange}
                        type="email"
                        name="email"
                        className="form-control"
                        id="confEmail"
                        defaultValue={state.enrollData.email}
                      />
                    </div>{" "}
                    <div className="send-confirmation">
                      <button
                        onClick={handleEmailSent}
                        className="btn btn-default"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                  <div
                    className="error"
                    {...(!emailSent && {
                      style: { display: "none" },
                    })}
                  >
                    Mail has been sent.
                  </div>
                </div>
                <div className="col-sm-6">
                  <button onClick={handlePrint} className="btn btn-default">
                    Print this Page
                  </button>
                </div>
              </div>
              <h3>Date and time of enrollment submission</h3>
              {state.enrollData.dor}
              <div>
                <p>The above is shown in Central time</p>
              </div>
              <h3>Your information</h3>
            </div>
            <br />

            {/*Row 1*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Product Selection*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Product selection
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">Choose a plan: </label>{" "}
                  {state.enrollData.planname}
                </div>
              </div>

              {/*Personal Information*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Personal information
                  </div>
                </div>

                <div className="panel-body">
                  <div
                    {...(state.enrollData.sal === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Title:</label>{" "}
                    {state.enrollData.sal}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.firstname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">First name: </label>{" "}
                    {state.enrollData.firstname}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.mi === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Middle initial:</label>{" "}
                    {state.enrollData.mi}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.lastname === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Last name:</label>{" "}
                    {state.enrollData.lastname}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.dob === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Date of birth:</label>{" "}
                    {state.enrollData.dob}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.sex === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Gender:</label>{" "}
                    {state.enrollData.sex}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permst1 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address:</label>{" "}
                    {state.enrollData.permst1}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permst2 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Address 2</label>{" "}
                    {state.enrollData.permst2}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permcity === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">City:</label>{" "}
                    {state.enrollData.permcity}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permstate === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">State:</label>{" "}
                    {state.enrollData.permstate}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permzip5 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip code (xxxxx):</label>{" "}
                    {state.enrollData.permzip5}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.permzip4 === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Zip+4 (xxxx)</label>{" "}
                    {state.enrollData.permzip4}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.homephone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Home phone:</label>{" "}
                    {state.enrollData.homephone}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.altphone === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Alternate phone:</label>{" "}
                    {state.enrollData.altphone}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.email === "" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Email address</label>{" "}
                    {state.enrollData.email}
                    <br />
                  </div>
                  <div
                    {...(!state.enrollData.acceptTerms && {
                      style: { display: "none" },
                    })}
                  >
                    <div
                      {...(state.enrollData.mailst1 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing Address:</label>{" "}
                      {state.enrollData.mailst1}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailst2 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Mailing Address 2:
                      </label>{" "}
                      {state.enrollData.mailst2}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailcity === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing City:</label>{" "}
                      {state.enrollData.mailcity}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailstate === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing State:</label>{" "}
                      {state.enrollData.mailstate}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailzip5 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Mailing Zip Code (xxxxx):
                      </label>{" "}
                      {state.enrollData.mailzip5}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.mailzip4 === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Mailing Zip+4:</label>{" "}
                      {state.enrollData.mailzip4}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Row 2*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Medicare Information*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Medicare information
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">
                    Medicare claim (ID) number:{" "}
                  </label>{" "}
                  {state.enrollData.medicareclaimnum}
                </div>
              </div>

              {/*Additional Questions*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Additional questions
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">
                    Will you have other prescription drug coverage in addition
                    to MedicareBlue Rx?:
                  </label>{" "}
                  {state.enrollData.adddrugcov === "Y" ? "Yes" : "No"}
                  <div
                    {...(state.enrollData.adddrugcov !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <div
                      {...(state.enrollData.addcovname === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Name of other coverage
                      </label>{" "}
                      {state.enrollData.addcovname}
                      <br />
                    </div>

                    <div
                      {...(state.enrollData.addcovid === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Member number for this coverage
                      </label>{" "}
                      {state.enrollData.addcovid}
                      <br />
                    </div>

                    <div
                      {...(state.enrollData.addgroupid === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        Group number for this coverage
                      </label>{" "}
                      {state.enrollData.addgroupid}
                      <br />
                    </div>

                    <div
                      {...(state.enrollData.instname === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Name of facility</label>{" "}
                      {state.enrollData.instname}
                      <br />
                    </div>

                    <div
                      {...(state.enrollData.instadd === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Street address:</label>{" "}
                      {state.enrollData.instadd}
                      <br />
                    </div>
                    <label className="control-label">City:</label>

                    <div
                      {...(state.enrollData.inststate === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">State:</label>{" "}
                      {state.enrollData.inststate}
                      <br />
                    </div>

                    <div
                      {...(state.enrollData.instzip === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Zip:</label>{" "}
                      {state.enrollData.instzip}
                      <br />
                    </div>
                    <div
                      {...(state.enrollData.instphone === "" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">Phone:</label>{" "}
                      {state.enrollData.instphone}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Row 3*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Enrollment Period Determination*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Enrollment period determination
                  </div>
                </div>

                <div className="panel-body">
                  {/* <div
                    {...(state.enrollData.aep !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am enrolling during the annual enrollment period,
                      October 15 through December 7, for a January 1, 2023
                      effective date. :
                    </label>{" "}
                    {state.enrollData.aep === "Y" ? "Yes" : "No"}
                  </div> */}

                  <div
                    {...(state.enrollData.sepnewtomedicare !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am new to Medicare:{" "}
                    </label>{" "}
                    {state.enrollData.sepnewtomedicare === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">Part A date: </label>
                    {state.enrollData.partadate}
                    <br />
                    <label className="control-label">Part b date: </label>
                    {state.enrollData.partbdate}
                  </div>

                  <div
                    {...(state.enrollData.sepserviceareamove !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently moved outside of the service area for my
                      current plan or I recently moved and this plan is a new
                      option for me. :
                    </label>{" "}
                    {state.enrollData.sepserviceareamove === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">I moved on:</label>{" "}
                    {state.enrollData.sepserviceareamovedate}
                    <br />
                    <label className="control-label">
                      Requested effective date:
                    </label>{" "}
                    {state.enrollData.effectivedate}
                  </div>
                  <br />
                  <div
                    {...(!state.enrollData.ltc && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I live in or recently moved out of a long-term care
                      facility :
                    </label>{" "}
                    {state.enrollData.sepltc === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      I moved/will move into/out of the facility on
                    </label>{" "}
                    {state.enrollData.sepltcdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepreturn2us !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently returned to the United States after living
                      permanently outside of t he U.S.:
                    </label>{" "}
                    {state.enrollData.sepreturn2us === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      I returned to the U.S. on:
                    </label>{" "}
                    {state.enrollData.sepreturn2usdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.seplostcoverage !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently involuntarily lost my creditable prescription
                      drug coverage (coverage as good as Medicare's) or was
                      notified of the loss (whichever is later).:
                    </label>{" "}
                    {state.enrollData.seplostcoverage === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      I lost my drug coverage on:
                    </label>{" "}
                    {state.enrollData.seplostcoveragedate}
                    <br />
                    <label className="control-label">
                      Requested effective date:
                    </label>{" "}
                    {state.enrollData.sepotherdate3}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepdisenrlsnp !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am being disenrolled from a special needs plan because
                      my condition does not qualify me for that plan:
                    </label>{" "}
                    {state.enrollData.sepdisenrlsnp === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      Disenrollment date:
                    </label>{" "}
                    {state.enrollData.sepdisenrlsnpdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepnonrenewal !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label>
                      I am being disenrolled from my existing plan due to its
                      non-renewal:
                    </label>{" "}
                    {state.enrollData.sepnonrenewal === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      Disrollment date
                    </label>{" "}
                    {state.enrollData.sepnonrenewaldate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.seplostpdppartb !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I have been disenrolled from a Medicare
                      Advantage-Prescription Drug plan due to loss of Part B but
                      continue to be entitled to Part A:
                    </label>{" "}
                    {state.enrollData.seplostpdppartb === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      Disenrollment date
                    </label>{" "}
                    {state.enrollData.seplostpdppartbdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepmaoep !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am enrolled in a Medicare Advantage plan and want to
                      make a change during the Medicare Advantage Open
                      Enrollment Period (MA OEP) of January 1 to March 31.:
                    </label>{" "}
                    {state.enrollData.sepmaoep === "Y" ? "Yes" : "No"}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepleavingempcoverage !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am leaving employer or union coverage:
                    </label>{" "}
                    {state.enrollData.sepleavingempcoverage === "Y"
                      ? "Yes"
                      : "No"}
                    <br />
                    <label className="control-label">Leave date</label>{" "}
                    {state.enrollData.sepleavingempcoveragedate}
                    <br />
                    <label className="control-label">
                      Requested effective date
                    </label>{" "}
                    {state.enrollData.sepotherdate2}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepleavingma !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am leaving my Medicare Advantage plan within 12 months
                      of my initial enrollment under a special enrollment period
                      to go back to a Medigap (Medicare supplement) plan:
                    </label>{" "}
                    {state.enrollData.sepleavingma === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">I left on:</label>{" "}
                    {state.enrollData.sepleavingmadate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepdisenrollmcp !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I am leaving employer or union coverage:
                    </label>{" "}
                    {state.enrollData.sepdisenrollmcp === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">Leave date</label>{" "}
                    {state.enrollData.sepdisenrollmcpdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepextrahelp !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently had a change in my Extra Help paying for
                      Medicare prescription drug coverage:
                    </label>{" "}
                    {state.enrollData.sepextrahelp === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      Date of change :
                    </label>{" "}
                    {state.enrollData.sepextrahelpdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepmedicarepremcoverage !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I have both Medicare and Medicaid:
                    </label>{" "}
                    {state.enrollData.sepmedicarepremcoverage === "Y"
                      ? "Yes"
                      : "No"}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepmedicaid !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently had a change in my Medicaid:
                    </label>{" "}
                    {state.enrollData.sepmedicaid === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      Date of change:
                    </label>{" "}
                    {state.enrollData.sepmedicaiddate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.seplawfulpresence !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I recently obtained lawful presence in the United States:
                    </label>{" "}
                    {state.enrollData.seplawfulpresence === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      I got this status on:
                    </label>{" "}
                    {state.enrollData.seplawfulpresencedate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepchoosedifferent !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I was enrolled in a plan by Medicare (or my state) and I
                      want to choose a different plan:
                    </label>{" "}
                    {state.enrollData.sepchoosedifferent === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      My enrollment in that plan started on
                    </label>{" "}
                    {state.enrollData.sepchoosedifferentdate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepemergency !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I was affected by a weather-related emergency or major
                      disaster (as declared by the Federal Emergency Management
                      Agency (FEMA)).:
                    </label>{" "}
                    {state.enrollData.sepemergency === "Y" ? "Yes" : "No"}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.sepincarceration !== "Y" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      I was recently released from incarceration:
                    </label>{" "}
                    {state.enrollData.sepincarceration === "Y" ? "Yes" : "No"}
                    <br />
                    <label className="control-label">
                      I was released on
                    </label>{" "}
                    {state.enrollData.sepincarcerationdate}
                  </div>

                  <div
                    {...(!state.enrollData.other && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Other special enrollment period not identified above :
                    </label>
                    <label className="control-label">Reason:</label>{" "}
                    {state.enrollData.sepothertxt}
                  </div>

                  {/* <div
                                      {...(values.sepnewtomedicare === "" && {
                                        style: { display: "none" },
                                      })}
                                    >
                                      <label>
                                        I belong to Big Sky Rx (a State
                                        pharmaceutical assistance program)
                                        provided by the state of Montana:
                                      </label>
                                    </div> */}
                </div>
              </div>

              {/*Premium Payment Option*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Premium payment option
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">Payment options: </label>{" "}
                  {getPaymentOptionText()}
                  <br />
                  <div
                    {...(state.enrollData.paymentoption !== "EFT" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Account holder name:
                    </label>{" "}
                    {state.enrollData.accountholdername}
                    <br />
                    <label className="control-label">Bank name:</label>{" "}
                    {state.enrollData.bankname}
                    <br />
                    <label className="control-label">Account type:</label>{" "}
                    {state.enrollData.accttype}
                    <br />
                    <label className="control-label">
                      Bank routing number:
                    </label>{" "}
                    {state.enrollData.bankroutingnumber}
                    <br />
                    <label className="control-label">
                      Bank account number:
                    </label>{" "}
                    {state.enrollData.bankacctnumber}
                    <br />
                  </div>
                </div>
              </div>
            </div>

            {/*Row 4*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Enrollment Authorization*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Enrollment authorization
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">
                    Enrollment authorization agreement:{" "}
                  </label>{" "}
                  {state.enrollData.acceptTerms ? "Yes" : "No"}
                </div>
              </div>

              {/*Applicant/Auth Rep Signature*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Applicant or authorized representative signature
                  </div>
                </div>

                <div className="panel-body">
                  <div>
                    <label className="control-label">Today's date:</label>{" "}
                    {moment().format("MM/DD/YYYY")}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">Enrollee first name</label>{" "}
                    {state.enrollData.firstname}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">Enrollee last name:</label>{" "}
                    {state.enrollData.lastname}
                    <br />
                  </div>

                  <div>
                    <label className="control-label">
                      Person completing document:
                    </label>{" "}
                    {getPreparerText()}
                    <br />
                  </div>

                  <div
                    {...(!state.enrollData.callCenterAssist && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">Call center</label>

                    <label className="control-label">
                      Call center representative (first name last name):
                    </label>

                    <label className="control-label">Call record number</label>
                  </div>

                  <div
                    {...(state.enrollData.preparer !== "authRep" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Representative first name:
                    </label>{" "}
                    {state.enrollData.authrepfirstname}
                    <br />
                    <label className="control-label">
                      Representative last name:
                    </label>{" "}
                    {state.enrollData.authreplastname}
                    <br />
                    <label className="control-label">
                      Relationship to enrollee:
                    </label>{" "}
                    {state.enrollData.authreprelationship}
                    <br />
                    <label className="control-label">
                      Street address:
                    </label>{" "}
                    {state.enrollData.authrepstreet}
                    <br />
                    <label className="control-label">City:</label>{" "}
                    {state.enrollData.authrepcity}
                    <br />
                    <label className="control-label">State:</label>{" "}
                    {state.enrollData.authrepstate}
                    <br />
                    <label className="control-label">Zip code:</label>{" "}
                    {state.enrollData.authrepzip}
                    <br />
                    <label className="control-label">Phone number:</label>{" "}
                    {state.enrollData.authrepphone}
                    <br />
                    <label className="control-label">
                      I want all of my information sent to this address.
                    </label>{" "}
                    {state.enrollData.authrepmail}
                    <br />
                  </div>
                  <div
                    {...(state.enrollData.preparer !== "assisted" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Assistor signed (First Name Last Name):
                    </label>{" "}
                    {state.enrollData.assistorsigned}
                    <br />
                    <label className="control-label">
                      Relationship to enrollee:
                    </label>{" "}
                    {state.enrollData.assistorrelationship2applicant}
                    <br />
                    <label className="control-label">
                      Assistor date signed
                    </label>{" "}
                    {state.enrollData.assistorsigneddate}
                    <br />
                  </div>

                  <div
                    {...(state.enrollData.preparer !== "agent" && {
                      style: { display: "none" },
                    })}
                  >
                    <label className="control-label">
                      Certified agent first name:
                    </label>{" "}
                    {state.enrollData.agentfirst}
                    <br />
                    <label className="control-label">
                      Certified agent last name:
                    </label>{" "}
                    {state.enrollData.agentlast}
                    <br />
                    <label className="control-label">
                    Agent number:
                    </label>{" "}
                    {state.enrollData.agentid}
                    <br />
                    <label className="control-label">
                    Agency number:
                    </label>{" "}
                    {state.enrollData.agencyid}
                    <br />
                    <label className="control-label">Agent phone number:</label>{" "}
                    {state.enrollData.agentphone}
                    <br />
                    <label className="control-label">
                      Please specify whether enrollee is present or if Agent has
                      paper enrollment (attestation) in hand:
                    </label>{" "}
                    {state.enrollData.agentSignedPaper === "attestation"
                      ? "Agent has paper enrollment (attestation) in hand"
                      : state.enrollData.agentSignedPaper === "present"
                      ? "Enrollee is present"
                      : ""}
                    <br />
                    <div
                      {...(state.enrollData.agentSignedPaper === "present" && {
                        style: { display: "none" },
                      })}
                    >
                      <label className="control-label">
                        What date did the enrollee sign the paper enrollment
                        form:
                      </label>{" "}
                      {state.enrollData.datesignedpaper}
                      <br />
                      <label className="control-label">
                        What date did you, the agent, sign the paper enrollment
                        form
                      </label>{" "}
                      {state.enrollData.agentsigned}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Row 5*/}
            <div
              className="row form-group js-form-wrapper form-wrapper"
              data-drupal-selector="edit-row-1-container"
              data-msg-required="This field is required."
              id="edit-row-1-container"
            >
              {/*Empty Section*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              ></div>

              {/*Accessible Format Selection*/}
              <div
                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                data-drupal-selector="edit-review-information"
                data-msg-required="Product selection is required."
                id="edit-review-information"
              >
                <div className="panel-heading">
                  <div className="panel-title collapse-link">
                    Accessible format selection
                  </div>
                </div>

                <div className="panel-body">
                  <label className="control-label">
                    Accessible format options:{" "}
                  </label>{" "}
                  <div
                    {...(!state.enrollData.braille && {
                      style: { display: "none" },
                    })}
                  >
                    Braille
                  </div>
                  <div
                    {...(!state.enrollData.largePrint && {
                      style: { display: "none" },
                    })}
                  >
                    Large Print
                  </div>
                  <div
                    {...(!state.enrollData.cd && {
                      style: { display: "none" },
                    })}
                  >
                    Audio CD
                  </div>
                </div>
              </div>
            </div>

                            {/*Row 6*/}
                            {/* <div
                              className="row form-group js-form-wrapper form-wrapper"
                              data-drupal-selector="edit-row-1-container"
                              data-msg-required="This field is required."
                              id="edit-row-1-container"
                            >

                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-ethnicity-information"
                                data-msg-required="Ethnicity selection is required."
                                id="edit-review-ethnicity-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Ethnicity selection
                                  </div>
                                </div>

                                <div className="panel-body">
                                  <label className="control-label">
                                    Ethnicity options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.ethnicityNA && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    No, not of Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityMX && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Mexican, Mexican American, Chicano/a
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityPR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Puerto Rican
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityCUB && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Yes, Cuban
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityOTH && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Yes, another Hispanic, Latino/a, or Spanish origin
                                  </div>
                                  <div
                                    {...(!state.enrollData.ethnicityNR && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    I choose not to answer.
                                  </div>

                                </div>
                              </div>

                              <div
                                className="col-sm-6 col-md-6 form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                                data-drupal-selector="edit-review-race-information"
                                data-msg-required="Race selection is required."
                                id="edit-review-race-information"
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    Race selection
                                  </div>
                                </div>

                                <div className="panel-body">
                                  <label className="control-label">
                                    Race options:{" "}
                                  </label>{" "}
                                  <div
                                    {...(!state.enrollData.race300 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    American Indian or Alaska Native
                                  </div>
                                  <div
                                    {...(!state.enrollData.race401 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Asian Indian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race201 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Black or African American
                                  </div>
                                  <div
                                    {...(!state.enrollData.race411 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Chinese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race421 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Filipino
                                  </div>
                                  <div
                                    {...(!state.enrollData.race521 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                    Guamanian or Chamorro
                                  </div>

                                  <div
                                    {...(!state.enrollData.race431 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Japanese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race441 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Korean
                                  </div>
                                  <div
                                    {...(!state.enrollData.race501 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Native Hawaiian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race499 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Asian
                                  </div>
                                  <div
                                    {...(!state.enrollData.race599 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Other Pacific Islander
                                  </div>
                                  <div
                                    {...(!state.enrollData.race511 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Samoan
                                  </div>
                                  <div
                                    {...(!state.enrollData.race451 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   Vietnamese
                                  </div>
                                  <div
                                    {...(!state.enrollData.race101 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   White
                                  </div>
                                  <div
                                    {...(!state.enrollData.race999 && {
                                      style: { display: "none" },
                                    })}
                                  >
                                   I choose not to answer
                                  </div>

                                </div>
                              </div>
                            </div> */}

          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollmentConfirmation_2023;
