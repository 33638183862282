import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_VERIFY_API_URL;

export async function verifyBankAccount(acctInfo) {
  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(acctInfo),
    });
    return handleResponse(response);
  } catch (error) {
    // console.log(error.)
    return handleError(error);
  }
}
