import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Terms2023 from "../../../Enroll/2023/Terms2023";
import moment from "moment";
import { Button } from "@material-ui/core";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../../../state/actions/updateEnrollData";
import CSPhoneInput from "../../../Common/CSPhoneInput";
import CSDateInput from "../../../Common/CSDateInput";

const Step5_2023 = ({
  handleChange,
  acceptTerms,
  handleNext,
  handlePrevious,
  activeStep,
  steps,
}) => {
  const {
    register,

    formState: { errors, isValid },
    control,
    trigger,
    clearErrors
  } = useForm({
    mode: "all",
  });

  const { state, actions } = useStateMachine({ updateEnrollData });

  const [termsOpen, setTermsOpen] = useState(false);

  const handleTermsOpen = () => {
    setTermsOpen(true);
  };

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const handleCallCenterAssist = () => {
    actions.updateEnrollData({
      callCenterAssist: !state.enrollData.callCenterAssist,
    });
    handleChange("callcenter");
    // console.log(state.enrollData.callCenterAssist);
  };

  const handlePreparer = (e) => {
    let p = e.target.value;
    actions.updateEnrollData({ preparer: p });
    clearErrors();
  };

  // console.log("Preparer: " + state.enrollData.preparer);

  const handleAccessibilityFormat = (e) => {

    let accessibilityFormat = {};

    switch (e.target.value) {
      case "braille": {
        let b = !state.enrollData.braille;
        actions.updateEnrollData({ braille: b });
        // console.log("Braille: " + b);
        accessibilityFormat = {
          Braille: b,
          LargePrint: state.enrollData.largePrint,
          AudioCD: state.enrollData.cd,
        };
        break;
      }
      case "largePrint": {
        let l = !state.enrollData.largePrint;
        actions.updateEnrollData({ largePrint: l });
        // console.log("Large Print: " + l);
        accessibilityFormat = {
          Braille: state.enrollData.braille,
          LargePrint: l,
          AudioCD: state.enrollData.cd,
        };
        break;
      }
      case "cd": {
        let c = !state.enrollData.cd;
        actions.updateEnrollData({ cd: c });
        // console.log("Audio CD: " + c);
        accessibilityFormat = {
          Braille: state.enrollData.braille,
          LargePrint: state.enrollData.largePrint,
          AudioCD: c,
        };
        break;
      }
      default:
        break;
    }

    // let accessibilityFormat = {
    //   Braille: state.enrollData.braille,
    //   LargePrint: state.enrollData.largePrint,
    //   AudioCD: state.enrollData.cd,
    // };

    actions.updateEnrollData({
      accessibilityformatoption: JSON.stringify(accessibilityFormat),
    });

    // console.log("AF triggered.");
  };

  // console.log('state.enrollData.largePrint:' + state.enrollData.largePrint);
  // console.log('state.enrollData.accessibilityformatoption:' + state.enrollData.accessibilityformatoption);

  // console.log(state.enrollData.accessibilityformatoption);

  // const handleEthnicity = (e) => {

  //   let ethnicity = [];

  //   switch (e.target.value) {
  //     case "ethnicityNA": {
  //       let na = !state.enrollData.ethnicityNA;
  //       actions.updateEnrollData({ ethnicityNA: na });   
  //       if(na === true) {
  //         ethnicity.push('NA');
  //       }    
  //       break;
  //     }
  //     case "ethnicityPR": {
  //       let pr = !state.enrollData.ethnicityPR;
  //       actions.updateEnrollData({ ethnicityPR: pr });       
  //       if(pr === true) {
  //         ethnicity.push('PR');
  //       }  
  //       break;
  //     }
  //     case "ethnicityOTH": {
  //       let oth = !state.enrollData.ethnicityOTH;
  //       actions.updateEnrollData({ ethnicityOTH: oth });   
  //       if(oth === true) {
  //         ethnicity.push('OTH');
  //       }      
  //       break;
  //     }
  //     case "ethnicityMX": {
  //       let mx = !state.enrollData.ethnicityMX;
  //       actions.updateEnrollData({ ethnicityMX: mx }); 
  //       if(mx === true) {
  //         ethnicity.push('MX');
  //       }        
  //       break;
  //     }
  //     case "ethnicityCUB": {
  //       let cub = !state.enrollData.ethnicityCUB;
  //       actions.updateEnrollData({ ethnicityCUB: cub });
  //       if(cub === true) {
  //         ethnicity.push('CUB');
  //       }         
  //       break;
  //     }
  //     case "ethnicityNR": {
  //       let nr = !state.enrollData.ethnicityNR;
  //       actions.updateEnrollData({ ethnicityNR: nr });    
  //       if(nr === true) {
  //         ethnicity.push('NR');
  //       }     
  //       break;
  //     }

  //     default:
  //       break;
  //   }

        
  //   if(state.enrollData.ethnicityNA === true
  //     && e.target.value != 'ethnicityNA') {
  //     ethnicity.push('NA');
  //   }

  //   if(state.enrollData.ethnicityPR === true
  //     && e.target.value != 'ethnicityPR') {
  //     ethnicity.push('PR');
  //   }

  //   if(state.enrollData.ethnicityOTH === true
  //     && e.target.value != 'ethnicityOTH') {
  //     ethnicity.push('OTH');
  //   }

  //   if(state.enrollData.ethnicityMX === true
  //     && e.target.value != 'ethnicityMX') {
  //     ethnicity.push('MX');
  //   }

  //   if(state.enrollData.ethnicityCUB === true
  //     && e.target.value != 'ethnicityCUB') {
  //     ethnicity.push('CUB');
  //   }

  //   if(state.enrollData.ethnicityNR === true
  //     && e.target.value != 'ethnicityNR') {
  //     ethnicity.push('NR');
  //   }


  //   //state.enrollData.ethnicity =
  //   //  JSON.stringify(ethnicity.join("|"));

  //   actions.updateEnrollData({
  //     ethnicity: ethnicity.join("|")
  //   });

  // };


  // const handleRace = (e) => {

  //   let race = [];

  //   switch (e.target.value) {
  //     case "race300": {
  //       let r300 = !state.enrollData.race300;
  //       actions.updateEnrollData({ race300: r300 });   
  //       if(r300 === true) {
  //         race.push('300');
  //       }    
  //       break;
  //     }

  //     case "race401": {
  //       let r401 = !state.enrollData.race401;
  //       actions.updateEnrollData({ race401: r401 });   
  //       if(r401 === true) {
  //         race.push('401');
  //       }    
  //       break;
  //     }

  //     case "race201": {
  //       let r201 = !state.enrollData.race201;
  //       actions.updateEnrollData({ race201: r201 });   
  //       if(r201 === true) {
  //         race.push('201');
  //       }    
  //       break;
  //     }

  //     case "race411": {
  //       let r411 = !state.enrollData.race411;
  //       actions.updateEnrollData({ race411: r411 });   
  //       if(r411 === true) {
  //         race.push('411');
  //       }    
  //       break;
  //     }

  //     case "race421": {
  //       let r421 = !state.enrollData.race421;
  //       actions.updateEnrollData({ race421: r421 });   
  //       if(r421 === true) {
  //         race.push('421');
  //       }    
  //       break;
  //     }

  //     case "race521": {
  //       let r521 = !state.enrollData.race521;
  //       actions.updateEnrollData({ race521: r521 });   
  //       if(r521 === true) {
  //         race.push('521');
  //       }    
  //       break;
  //     }

  //     case "race431": {
  //       let r431 = !state.enrollData.race431;
  //       actions.updateEnrollData({ race431: r431 });   
  //       if(r431 === true) {
  //         race.push('431');
  //       }    
  //       break;
  //     }

  //     case "race441": {
  //       let r441 = !state.enrollData.race441;
  //       actions.updateEnrollData({ race441: r441 });   
  //       if(r441 === true) {
  //         race.push('441');
  //       }    
  //       break;
  //     }

  //     case "race501": {
  //       let r501 = !state.enrollData.race501;
  //       actions.updateEnrollData({ race501: r501 });   
  //       if(r501 === true) {
  //         race.push('501');
  //       }    
  //       break;
  //     }

  //     case "race499": {
  //       let r499 = !state.enrollData.race499;
  //       actions.updateEnrollData({ race499: r499 });   
  //       if(r499 === true) {
  //         race.push('499');
  //       }    
  //       break;
  //     }

  //     case "race599": {
  //       let r599 = !state.enrollData.race599;
  //       actions.updateEnrollData({ race599: r599 });   
  //       if(r599 === true) {
  //         race.push('599');
  //       }    
  //       break;
  //     }

  //     case "race511": {
  //       let r511 = !state.enrollData.race511;
  //       actions.updateEnrollData({ race511: r511 });   
  //       if(r511 === true) {
  //         race.push('511');
  //       }    
  //       break;
  //     }

  //     case "race451": {
  //       let r451 = !state.enrollData.race451;
  //       actions.updateEnrollData({ race451: r451 });   
  //       if(r451 === true) {
  //         race.push('451');
  //       }    
  //       break;
  //     }

  //     case "race101": {
  //       let r101 = !state.enrollData.race101;
  //       actions.updateEnrollData({ race101: r101 });   
  //       if(r101 === true) {
  //         race.push('101');
  //       }    
  //       break;
  //     }

  //     case "race999": {
  //       let r999 = !state.enrollData.race999;
  //       actions.updateEnrollData({ race999: r999 });   
  //       if(r999 === true) {
  //         race.push('999');
  //       }    
  //       break;
  //     }

  //     default:
  //       break;
  //   }

        
  //   if(state.enrollData.race300 === true
  //     && e.target.value != 'race300') {
  //     race.push('300');
  //   }

  //   if(state.enrollData.race401 === true
  //     && e.target.value != 'race401') {
  //     race.push('401');
  //   }

  //   if(state.enrollData.race201 === true
  //     && e.target.value != 'race201') {
  //     race.push('201');
  //   }

  //   if(state.enrollData.race411 === true
  //     && e.target.value != 'race411') {
  //     race.push('411');
  //   }

  //   if(state.enrollData.race421 === true
  //     && e.target.value != 'race421') {
  //     race.push('421');
  //   }

  //   if(state.enrollData.race521 === true
  //     && e.target.value != 'race521') {
  //     race.push('521');
  //   }

  //   if(state.enrollData.race431 === true
  //     && e.target.value != 'race431') {
  //     race.push('431');
  //   }

  //   if(state.enrollData.race441 === true
  //     && e.target.value != 'race441') {
  //     race.push('441');
  //   }

  //   if(state.enrollData.race501 === true
  //     && e.target.value != 'race501') {
  //     race.push('501');
  //   }

  //   if(state.enrollData.race499 === true
  //     && e.target.value != 'race499') {
  //     race.push('499');
  //   }

  //   if(state.enrollData.race599 === true
  //     && e.target.value != 'race599') {
  //     race.push('599');
  //   }

  //   if(state.enrollData.race511 === true
  //     && e.target.value != 'race511') {
  //     race.push('511');
  //   }

  //   if(state.enrollData.race451 === true
  //     && e.target.value != 'race451') {
  //     race.push('451');
  //   }

  //   if(state.enrollData.race101 === true
  //     && e.target.value != 'race101') {
  //     race.push('101');
  //   }

  //   if(state.enrollData.race999 === true
  //     && e.target.value != 'race999') {
  //     race.push('999');
  //   }

  //   actions.updateEnrollData({
  //     race: race.join("|")
  //   });

  // };

  //console.log(state.enrollData.ethnicity);
  //console.log(state.enrollData.race);

  let p1 = activeStep === 0 ? true : false;

  const [displayCheckOptionError, setDisplayCheckOptionError] = useState(false);

  const handleNextClick = async () => {
    const isValidForm = await trigger();  

    var radios = document.querySelectorAll('input[type="radio"]');
    var checkedOne = Array.prototype.slice.call(radios).some((x) => x.checked);
    if (checkedOne && isValidForm) {
      handleNext();
    } else if (!checkedOne && isValidForm) {
      setDisplayCheckOptionError(true);
    } else {
      trigger();
    }

    // console.log(displayCheckOptionError);
  };

  const handleAgentPhone = (e) => {
    actions.updateEnrollData({ agentphone: e.replace("+1", "") });
  };

  const handleAuthRepPhone = (e) => {
    actions.updateEnrollData({ authrepphone: e.replace("+1", "") });
  };

  const handleAgentSignedPaper = (e) => {
    actions.updateEnrollData({ agentSignedPaper: e.target.value });
  };

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-12 internal-page">
          <div className="region region-content">
            <div data-drupal-messages-fallback="" className="hidden"></div>

            <article className="webform full clearfix">
              <div className="content">
                <div
                  className="
                  field
                  field--name-body
                  field--type-text-with-summary
                  field--label-hidden
                  field__item
                "
                >
                  <p>
                    <span className="form-required"> </span> indicates required
                    information
                  </p>
                </div>

                <div
                  className="
                  field
                  field--name-webform
                  field--type-webform
                  field--label-hidden
                  field__item
                "
                >
                  <form
                    className="
                    webform-submission-form
                    webform-submission-add-form
                    webform-submission-mbrx-enroll-2021-form
                    webform-submission-mbrx-enroll-2021-add-form
                    webform-submission-mbrx-enroll-2021-node-234-form
                    webform-submission-mbrx-enroll-2021-node-234-add-form
                    js-webform-disable-autosubmit js-webform-details-toggle
                    webform-details-toggle
                  "
                    data-drupal-selector="webform-submission-mbrx-enroll-2021-node-234-add-form"
                    data-msg-required="This field is required."
                    action="/mbrx-enroll-2021"
                    method="post"
                    id="webform-submission-mbrx-enroll-2021-node-234-add-form"
                    acceptCharset="UTF-8"
                    //noValidate="noValidate"
                    data-drupal-form-fields="edit-step-1,edit-step-2,edit-step-3,edit-step-4,edit-agree-terms-enroll-auth,edit-datesigned,edit-signaturefirst,edit-signaturelast,edit-preparer-enrollee,edit-preparer-auth-rep,edit-preparer-assisted,edit-preparer-agent,edit-callcenter,edit-assistorsigned,edit-assistorrelationship2applicant,edit-assistorsigneddate,edit-agentfirst,edit-agentlast,edit-agentid,edit-enrollee-perm-state,edit-agencyid,edit-agentphone,edit-callcentername,edit-callcenterrep,edit-ridnbr,edit-please-specify-one-present,edit-please-specify-one-attestation,edit-datesignedpaper,edit-agentsigned,edit-has-authrep-info,edit-authrepfirstname,edit-authreplastname,edit-authreprelationship,edit-authrepstreet,edit-authrepcity,edit-authrepstate,edit-authrepzip,edit-authrepphone,edit-authrepmail,edit-accessibleformatoption-braille,edit-accessibleformatoption-large-print,edit-accessibleformatoption-audio-cd,edit-actions-wizard-prev,edit-actions-wizard-next"
                  >
                    <div
                      data-webform-key="step_5"
                      data-drupal-selector="edit-step-5"
                      className="js-form-wrapper form-wrapper form-group"
                      data-msg-required="Step 5 is required."
                      id="edit-step-5--2"
                    >
                      <div
                        id="edit-step-5-title"
                        className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
                      >
                        <h1>Enrollment Authorization</h1>
                      </div>

                      {/* <div
                        className="
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-enrollment-authorization"
                        data-msg-required="Enrollment authorization agreement is required."
                        id="edit-enrollment-authorization"
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Enrollment authorization agreement
                          </div>
                        </div>

                        <div className="panel-body">
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-checkbox
                            js-form-type-checkbox
                            form-item-agree-terms-enroll-auth
                            js-form-item-agree-terms-enroll-auth
                            checkbox
                          "
                          >
                            <label
                              htmlFor="edit-agree-terms-enroll-auth"
                              className="
                              control-label
                              option
                              js-form-required
                              form-required
                            "
                            >
                              <input
                                {...register("agree_terms_enroll_auth")}
                                onChange={handleChange(
                                  "agree_terms_enroll_auth"
                                )}
                                checked={state.enrollData.acceptTerms}
                                data-webform-required-error="Please agree with the terms as described in the Enrollment Authorization element"
                                data-drupal-selector="edit-agree-terms-enroll-auth"
                                aria-describedby="edit-agree-terms-enroll-auth--description"
                                className="form-checkbox required"
                                data-msg-required="Please agree with the terms as described in the Enrollment Authorization element"
                                type="checkbox"
                                id="edit-agree-terms-enroll-auth"
                                //name="agree_terms_enroll_auth"
                                value="1"
                                required="required"
                                disabled={!acceptTerms}
                                //aria-required="true"
                              />
                              I agree with the terms as described in the{" "}
                              <button
                                type="button"
                                onClick={handleTermsOpen}
                                data-colorbox-inline=".enroll-auth-cb"
                                className="btn btn-link btn-anchor btn-lg"
                              >
                                Enrollment authorization
                              </button>
                            </label>

                            <Terms2023
                              termsOpen={termsOpen}
                              handleTermsOpen={handleTermsOpen}
                              handleTermsClose={handleTermsClose}
                            />

                            <div className="description help-block">
                              <div
                                id="edit-agree-terms-enroll-auth--description"
                                className="webform-element-description form-group"
                              >
                                By selecting the checkbox above, I acknowledge
                                that I have read and understand its contents.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div
                        className="
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-auth-rep-sig"
                        aria-describedby="edit-auth-rep-sig--description"
                        data-msg-required="Applicant or authorized representative signature is required."
                        id="edit-auth-rep-sig"
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Applicant or authorized representative signature
                          </div>
                        </div>

                        <div className="panel-body">
                          <p
                            id="edit-auth-rep-sig--description"
                            className="help-block"
                          ></p>
                          <div
                            className="
                            form-item
                            webform-component webform-component-markup
                          "
                            id="webform-component-auth-rep-sig--applicant-authorized-rep-sig-description"
                          >
                            <p>
                              <strong>I understand</strong> that by typing my
                              name below on this application means that I have
                              read and understand the contents of this
                              application, including the enrollment
                              authorization information in Step 4.
                            </p>

                            <p>
                              If this is submitted by an individual who is the
                              authorized representative of the enrollee and
                              authorized to act on his/her behalf, this
                              signature certifies that
                            </p>

                            <ol className="ml-4">
                              <li>
                                this person is authorized under state law to
                                complete this enrollment and
                              </li>
                              <li>
                                documentation of this authority is available
                                upon request by Medicare.
                              </li>
                            </ol>
                          </div>
                          <p></p>
                          <div
                            data-drupal-states='{"disabled":{"":{"":""}}}'
                            className="
                            form-inline form-item
                            js-form-item
                            form-type-date
                            js-form-type-date
                            form-item-datesigned
                            js-form-item-datesigned
                            form-disabled form-group
                          "
                          >
                            <label
                              htmlFor="edit-datesigned"
                              className="control-label"
                            >
                              Today's date
                            </label>

                            <input
                              {...register("datesigned", {
                                min: moment().format("YYYY-MM-DD"),
                              })}
                              onChange={handleChange("datesigned")}
                              type="text"
                              data-drupal-date-format="m/d/Y"
                              data-drupal-selector="edit-datesigned"
                              disabled="disabled"
                              className="form-text form-control hasDatepicker"
                              data-msg-required="Today's date is required."
                              id="edit-datesigned"
                              name="datesigned"
                              //value="06/16/2021"
                              data-drupal-states='{"disabled":{"":{"":""}}}'
                              placeholder="MM/DD/YYYY"
                              autoComplete="chrome-off-21176153"
                              defaultValue={moment().format("MM/DD/YYYY")}
                              readOnly
                            />
                          </div>
                          <div
                            className="
                            row
                            form-group
                            js-form-wrapper
                            form-wrapper
                          "
                            data-drupal-selector="edit-auth-rep-row-container"
                            data-msg-required="This field is required."
                            id="edit-auth-rep-row-container"
                          >
                            <div
                              className="
                              col-md-6 col-sm-6
                              form-item
                              js-form-item
                              form-type-textfield
                              js-form-type-textfield
                              form-item-signaturefirst
                              js-form-item-signaturefirst
                              form-group
                            "
                            >
                              <label
                                htmlFor="edit-signaturefirst"
                                className="
                                control-label
                                js-form-required
                                form-required
                              "
                              >
                                Enrollee first name
                              </label>

                              <input
                                {...register("signaturefirst", {
                                  required: "Signature first name is required.",
                                  pattern: {
                                    value: /^[-a-zA-Z0-9 ]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                  },
                                })}
                                value={state.enrollData.signaturefirst}
                                onChange={handleChange("signaturefirst")}
                                pattern="^[-a-zA-Z0-9 ]*$"
                                data-webform-pattern-error="Special characters are not allowed."
                                data-drupal-selector="edit-signaturefirst"
                                className="form-text required form-control"
                                // data-msg-maxLength="Enrollee first name field has a maximum length of 25."
                                // data-msg-required="Enrollee first name is required."
                                // data-msg-pattern="Special characters are not allowed."
                                type="text"
                                id="edit-signaturefirst"
                                //name="signaturefirst"
                                //value=""
                                size="60"
                                maxLength="25"
                                required="required"
                                aria-required="true"
                              />
                              <div className="error">
                                <ErrorMessage
                                  errors={errors}
                                  name="signaturefirst"
                                >
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                            <div
                              className="
                              col-md-6 col-sm-6
                              form-item
                              js-form-item
                              form-type-textfield
                              js-form-type-textfield
                              form-item-signaturelast
                              js-form-item-signaturelast
                              form-group
                            "
                            >
                              <label
                                htmlFor="edit-signaturelast"
                                className="
                                control-label
                                js-form-required
                                form-required
                              "
                              >
                                Enrollee last name
                              </label>

                              <input
                                {...register("signaturelast", {
                                  required: "Signature last name is required.",
                                  pattern: {
                                    value: /^[-a-zA-Z0-9 ]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                  },
                                })}
                                value={state.enrollData.signaturelast}
                                onChange={handleChange("signaturelast")}
                                pattern="^[-a-zA-Z0-9 ]*$"
                                data-webform-pattern-error="Special characters are not allowed."
                                data-drupal-selector="edit-signaturelast"
                                className="form-text required form-control"
                                // data-msg-maxLength="Enrollee last name field has a maximum length of 25."
                                // data-msg-required="Enrollee last name is required."
                                // data-msg-pattern="Special characters are not allowed."
                                type="text"
                                id="edit-signaturelast"
                                // name="signaturelast"
                                // value=""
                                size="60"
                                maxLength="25"
                                required="required"
                                aria-required="true"
                              />
                              <div className="error">
                                <ErrorMessage
                                  errors={errors}
                                  name="signaturelast"
                                >
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <input
                            data-drupal-selector="edit-signature"
                            data-msg-required="signature confirm is required."
                            type="hidden"
                            name="signature"
                            value="Y"
                          />
                          <fieldset
                            data-drupal-selector="edit-preparer"
                            className="
                            radios--wrapper
                            fieldgroup
                            form-composite
                            webform-composite-hidden-title
                            required
                            js-webform-type-radios
                            webform-type-radios
                            js-form-item
                            form-item
                            js-form-wrapper
                            form-wrapper
                          "
                            data-msg-required="preparer is required."
                            id="edit-preparer--wrapper"
                          >
                            <legend>
                              <span
                                className="
                                visually-hidden
                                fieldset-legend
                                js-form-required
                                form-required
                              "
                              >
                                preparer
                              </span>
                            </legend>
                            <div className="fieldset-wrapper">
                              <div
                                id="edit-preparer"
                                className="
                                js-webform-radios
                                webform-options-display-one-column
                              "
                              >
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-preparer
                                  js-form-item-preparer
                                  radio
                                "
                                >
                                  <label
                                    //htmlFor="edit-preparer-enrollee"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handlePreparer}
                                      checked={
                                        state.enrollData.preparer === "enrollee"
                                      }
                                      data-drupal-selector="edit-preparer-enrollee"
                                      className="form-radio"
                                      data-msg-required="preparer is required."
                                      type="radio"
                                      id="edit-preparer-enrollee"
                                      name="preparer"
                                      value="enrollee"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    I am the enrollee
                                  </label>
                                </div>
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-preparer
                                  js-form-item-preparer
                                  radio
                                "
                                >
                                  <label
                                    //htmlFor="edit-preparer-auth-rep"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handlePreparer}
                                      checked={
                                        state.enrollData.preparer === "authRep"
                                      }
                                      data-drupal-selector="edit-preparer-auth-rep"
                                      className="form-radio"
                                      data-msg-required="preparer is required."
                                      type="radio"
                                      id="edit-preparer-auth-rep"
                                      name="preparer"
                                      value="authRep"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    I am the enrollee's authorized
                                    representative
                                  </label>
                                </div>
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-preparer
                                  js-form-item-preparer
                                  radio
                                "
                                >
                                  <label
                                    //htmlFor="edit-preparer-assisted"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handlePreparer}
                                      checked={
                                        state.enrollData.preparer === "assisted"
                                      }
                                      data-drupal-selector="edit-preparer-assisted"
                                      className="form-radio"
                                      data-msg-required="preparer is required."
                                      type="radio"
                                      id="edit-preparer-assisted"
                                      name="preparer"
                                      value="assisted"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    I assisted the enrollee in filling out the
                                    form
                                  </label>
                                </div>
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-preparer
                                  js-form-item-preparer
                                  radio
                                "
                                >
                                  <label
                                    //htmlFor="edit-preparer-agent"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handlePreparer}
                                      checked={
                                        state.enrollData.preparer === "agent"
                                      }
                                      data-drupal-selector="edit-preparer-agent"
                                      className="form-radio"
                                      data-msg-required="preparer is required."
                                      type="radio"
                                      id="edit-preparer-agent"
                                      name="preparer"
                                      value="agent"
                                      required="required"
                                      //aria-required="true"
                                    />
                                    I am the enrollee's agent
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-checkbox
                            js-form-type-checkbox
                            form-item-callcenter
                            js-form-item-callcenter
                            checkbox
                          "
                            {...((state.enrollData.preparer === "enrollee" ||
                              state.enrollData.preparer === "") && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              //htmlFor="edit-callcenter"
                              className="control-label option"
                            >
                              <input
                                {...register("callcenter")}
                                onChange={handleCallCenterAssist}
                                //checked={callCenterAssist === "true"}
                                data-drupal-selector="edit-callcenter"
                                className="form-checkbox"
                                data-msg-required="I was assisted by a call center during this process is required."
                                type="checkbox"
                                id="edit-callcenter"
                                name="callcenter"
                                value={state.enrollData.callCenterAssist}
                                data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"!value":"enrollee"}}}'
                              />
                              I was assisted by a call center during this
                              process
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-assistance-provider-info"
                        data-msg-required="Assistance provider information is required."
                        data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"assisted"}}}'
                        id="edit-assistance-provider-info"
                        {...(state.enrollData.preparer !== "assisted" && {
                          style: { display: "none" },
                        })}
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Assistance provider information
                          </div>
                        </div>

                        <div className="panel-body">
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-textfield
                            js-form-type-textfield
                            form-item-assistorsigned
                            js-form-item-assistorsigned
                            form-group
                          "
                          >
                            <label
                              htmlFor="edit-assistorsigned"
                              className="control-label form-required"
                            >
                              Assistor signed (first name last name)
                            </label>

                            <input
                              {...register("assistorsigned", {
                                validate: {
                                  required: (value) => {
                                    if (
                                      !value &&
                                      state.enrollData.preparer === "assisted"
                                    )
                                      return "Assistor signed (first name last name) is required.";
                                    return true;
                                  },
                                },
                                pattern: {
                                  value: /[-A-Za-z]+(?:\s+[-A-Za-z]+)+/,
                                  message:
                                    "Assistor signed (first name last name) should be at least 2 words long.",
                                },
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Assistor signed (first name last name) field has a maximum length of 25.",
                                },
                              })}
                              value={state.enrollData.assistorsigned}
                              onChange={handleChange("assistorsigned")}
                              pattern="[-A-Za-z]+(?:\s+[-A-Za-z]+)+"
                              data-webform-pattern-error="Assistor signed (first name last name) should be at least 2 words long."
                              data-drupal-selector="edit-assistorsigned"
                              className="form-text form-control"
                              // data-msg-maxLength="Assistor signed (first name last name) field has a maximum length of 25."
                              // data-msg-required="Assistor signed (first name last name) is required."
                              // data-msg-pattern="Assistor signed (first name last name) should be at least 2 words long."
                              type="text"
                              id="edit-assistorsigned"
                              // name="assistorsigned"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"assisted"}}}'
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="assistorsigned"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-select
                            js-form-type-select
                            form-item-assistorrelationship2applicant
                            js-form-item-assistorrelationship2applicant
                            form-group
                          "
                          >
                            <label
                              htmlFor="edit-assistorrelationship2applicant"
                              className="control-label form-required"
                            >
                              Relationship to enrollee
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("assistorrelationship2applicant", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value &&
                                        state.enrollData.preparer === "assisted"
                                      )
                                        return "Relationship to enrollee is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={
                                  state.enrollData
                                    .assistorrelationship2applicant
                                }
                                onChange={handleChange(
                                  "assistorrelationship2applicant"
                                )}
                                data-drupal-selector="edit-assistorrelationship2applicant"
                                className="form-select form-control"
                                data-msg-required="Relationship to enrollee is required."
                                id="edit-assistorrelationship2applicant"
                                name="assistorrelationship2applicant"
                                data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"assisted"}}}'
                              >
                                <option value="">- Select -</option>
                                <option value="Family Member">
                                  Family member
                                </option>
                                <option value="Emergency Contact">
                                  Emergency contact
                                </option>
                                <option value="Attorney">Attorney</option>
                                <option value="Marketing Rep">
                                  Marketing rep
                                </option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="error">
                                <ErrorMessage
                                  errors={errors}
                                  name="assistorrelationship2applicant"
                                >
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            className="                        
                            webform-has-field-prefix
                            webform-has-field-suffix
                            form-inline form-item
                            js-form-item
                            form-type-date
                            js-form-type-date
                            form-item-assistorsigneddate
                            js-form-item-assistorsigneddate
                            form-disabled form-group
                          "
                          >
                            <label
                              htmlFor="edit-assistorsigneddate"
                              className="control-label"
                            >
                              Assistor date signed
                            </label>

                            <div className="input-group">
                              <span className="input-group-addon">
                                <div className="date_wrap_div">
                                  <input
                                    {...register("doassistorsigneddateb", {
                                      min: "2021-06-16",
                                    })}
                                    onChange={handleChange(
                                      "assistorsigneddate"
                                    )}
                                    type="date"
                                    value={values.assistorsigneddate}
                                    data-datepicker-button=""
                                    //type="text"
                                    data-drupal-date-format="m/d/Y"
                                    data-drupal-selector="edit-assistorsigneddate"
                                    disabled="disabled"
                                    className="
                                    form-text form-control
                                    hasDatepicker
                                  "
                                    data-msg-required="Assistor date signed is required."
                                    id="edit-assistorsigneddate"
                                    name="assistorsigneddate"
                                    //value="06/16/2021"
                                    placeholder="MM/DD/YYYY"
                                    autoComplete="chrome-off-99259714"
                                  />
                                  
                                  <span className="input-group-addon"></span>
                                </div>
                              </span>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div
                        {...(state.enrollData.preparer !== "authRep" && {
                          style: { display: "none" },
                        })}
                      >
                        <div
                          className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                          data-drupal-selector="edit-has-authrep"
                          data-msg-required="Enrollee authorized representative is required."
                          data-drupal-states='{"visible":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"callcenter"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}]}'
                          id="edit-has-authrep"
                          //style="display: none"
                        >
                          <div className="panel-heading">
                            <div className="panel-title collapse-link">
                              Enrollee authorized representative
                            </div>
                          </div>

                          <div className="panel-body">
                            <div
                              className="
                            form-item
                            js-form-item
                            form-type-checkbox
                            js-form-type-checkbox
                            form-item-has-authrep-info
                            js-form-item-has-authrep-info
                            checkbox
                          "
                            >
                              <label
                                htmlFor="edit-has-authrep-info"
                                className="control-label option"
                              >
                                <input
                                  {...register("has_authrep_info")}
                                  onChange={handleChange("has_authrep_info")}
                                  checked={
                                    state.enrollData.has_authrep_info === "1"
                                  }
                                  data-drupal-selector="edit-has-authrep-info"
                                  className="form-checkbox"
                                  data-msg-required="Enrollee has an authorized representative is required."
                                  type="checkbox"
                                  id="edit-has-authrep-info"
                                  name="has_authrep_info"
                                  value="1"
                                />
                                Enrollee has an authorized representative
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="
                        js-webform-states-hidden
                        form-group
                        js-form-wrapper
                        form-wrapper
                      "
                          data-drupal-selector="edit-rep-container"
                          data-msg-required="This field is required."
                          data-drupal-states='{"visible":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                          id="edit-rep-container"
                          //style="display: none"
                        >
                          <div
                            className="js-form-wrapper js-webform-states-hidden"
                            data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}}'
                            //style="display: none"
                          >
                            <div
                              className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                              id="edit-because-enrollee-processed-text"
                            >
                              <p>
                                Because enrollee has an authorized
                                representative, you MUST provide the following
                                information:
                              </p>
                            </div>
                          </div>
                          <div
                            className="js-form-wrapper js-webform-states-hidden"
                            data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}}}'
                            //style="display: none"
                          >
                            <div
                              className="
                            form-item
                            js-form-item
                            form-type-processed-text
                            js-form-type-processed-text
                            form-item-
                            js-form-item-
                            form-no-label form-group
                          "
                              id="edit-must-processed-text"
                            >
                              <div
                                className="
                              form-item
                              js-form-item
                              form-wrapper
                              js-form-wrapper
                              panel panel-default
                            "
                              >
                                <div className="panel-heading">
                                  <div className="panel-title collapse-link">
                                    If you are the authorized representative,
                                    you MUST provide the following information:
                                  </div>
                                </div>
                                <div className="panel-body"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authrepfirstname
                          js-form-item-authrepfirstname
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepfirstname"
                              className="control-label form-required"
                            >
                              Representative first name
                            </label>

                            <input
                              {...(state.enrollData.preparer === "authRep" && {
                                ...register("authrepfirstname", {
                                  required:
                                    "Representative first name is required.",

                                  pattern: {
                                    value: /^[-/a-zA-Z0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Representative first name field has a maximum length of 25.",
                                    },
                                  },
                                }),
                              })}
                              value={state.enrollData.authrepfirstname}
                              onChange={handleChange("authrepfirstname")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-authrepfirstname"
                              className="form-text form-control"
                              // data-msg-maxLength="Representative first name field has a maximum length of 25."
                              // data-msg-required="Representative first name is required."
                              // data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-authrepfirstname"
                              // name="authrepfirstname"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="authrepfirstname"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authreplastname
                          js-form-item-authreplastname
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authreplastname"
                              className="control-label form-required"
                            >
                              Representative last name
                            </label>

                            <input
                              {...(state.enrollData.preparer === "authRep" && {
                                ...register("authreplastname", {
                                  required:
                                    "Representative last name is required.",

                                  pattern: {
                                    value: /^[-/a-zA-Z0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Representative last name field has a maximum length of 25.",
                                    },
                                  },
                                }),
                              })}
                              value={state.enrollData.authreplastname}
                              onChange={handleChange("authreplastname")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-authreplastname"
                              className="form-text form-control"
                              // data-msg-maxLength="Representative last name field has a maximum length of 25."
                              // data-msg-required="Representative last name is required."
                              // data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-authreplastname"
                              // name="authreplastname"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="authreplastname"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-select
                          js-form-type-select
                          form-item-authreprelationship
                          js-form-item-authreprelationship
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authreprelationship"
                              className="control-label form-required"
                            >
                              Relationship to enrollee
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...(state.enrollData.preparer ===
                                  "authRep" && {
                                  ...register("authreprelationship", {
                                    required:
                                      "Relationship to enrollee is required.",
                                  }),
                                })}
                                value={state.enrollData.authreprelationship}
                                onChange={handleChange("authreprelationship")}
                                data-drupal-selector="edit-authreprelationship"
                                className="form-select form-control"
                                data-msg-required="Relationship to enrollee is required."
                                id="edit-authreprelationship"
                                name="authreprelationship"
                                data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                                defaultValue=""
                              >
                                <option value="">- Select -</option>
                                <option value="Family Member">
                                  Family member
                                </option>
                                <option value="Emergency Contact">
                                  Emergency contact
                                </option>
                                <option value="Attorney">Attorney</option>
                                <option value="Marketing Rep">
                                  Marketing Rep
                                </option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="error">
                                <ErrorMessage
                                  errors={errors}
                                  name="authreprelationship"
                                >
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authrepstreet
                          js-form-item-authrepstreet
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepstreet"
                              className="control-label form-required"
                            >
                              Street address
                            </label>

                            <input
                              {...(state.enrollData.preparer === "authRep" && {
                                ...register("authrepstreet", {
                                  required: "Street address is required.",

                                  pattern: {
                                    value: /^[-/a-zA-Z0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "Street address field has a maximum length of 25.",
                                    },
                                  },
                                }),
                              })}
                              value={state.enrollData.authrepstreet}
                              onChange={handleChange("authrepstreet")}
                              pattern="^[-/a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-authrepstreet"
                              className="form-text form-control"
                              // data-msg-maxLength="Street address field has a maximum length of 25."
                              // data-msg-required="Street address is required."
                              // data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-authrepstreet"
                              // name="authrepstreet"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="authrepstreet"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authrepcity
                          js-form-item-authrepcity
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepcity"
                              className="control-label form-required"
                            >
                              City
                            </label>

                            <input
                              {...(state.enrollData.preparer === "authRep" && {
                                ...register("authrepcity", {
                                  required: "City is required.",

                                  pattern: {
                                    value: /^[-/a-zA-Z0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                    maxLength: {
                                      value: 25,
                                      message:
                                        "City field has a maximum length of 25.",
                                    },
                                  },
                                }),
                              })}
                              value={state.enrollData.authrepcity}
                              onChange={handleChange("authrepcity")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-authrepcity"
                              className="form-text form-control"
                              // data-msg-maxLength="City field has a maximum length of 25."
                              // data-msg-required="City is required."
                              // data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-authrepcity"
                              // name="authrepcity"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="authrepcity">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-select
                          js-form-type-select
                          form-item-authrepstate
                          js-form-item-authrepstate
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepstate"
                              className="control-label form-required"
                            >
                              State
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...(state.enrollData.preparer ===
                                  "authRep" && {
                                  ...register("authrepstate", {
                                    required: "State is required.",
                                  }),
                                })}
                                value={state.enrollData.authrepstate}
                                onChange={handleChange("authrepstate")}
                                data-drupal-selector="edit-authrepstate"
                                className="form-select form-control"
                                data-msg-required="State is required."
                                id="edit-authrepstate"
                                //name="authrepstate"
                                data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                                defaultValue=""
                              >
                                <option value="">- Select -</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                              <div className="error">
                                <ErrorMessage
                                  errors={errors}
                                  name="authrepstate"
                                >
                                  {({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(
                                      ([type, message]) => (
                                        <p key={type}>{message}</p>
                                      )
                                    )
                                  }
                                </ErrorMessage>
                              </div>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authrepzip
                          js-form-item-authrepzip
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepzip"
                              className="control-label form-required"
                            >
                              Zip code
                            </label>

                            <input
                              {...(state.enrollData.preparer === "authRep" && {
                                ...register("authrepzip", {
                                  required: "Zip code is required.",
                                  minLength: {
                                    value: 5,
                                    message: "Please enter a 5-digit Zip code.",
                                  },
                                  maxLength: {
                                    value: 5,
                                    message:
                                      "Zip code field has a maximum length of 5.",
                                  },
                                  pattern: {
                                    value: /^[-/0-9 -]*$/,
                                    message:
                                      "Special characters are not allowed.",
                                  },
                                }),
                              })}
                              value={state.enrollData.authrepzip}
                              onChange={handleChange("authrepzip")}
                              data-drupal-selector="edit-authrepzip"
                              className="form-text form-control"
                              // data-msg-maxLength="Zip code field has a maximum length of 5."
                              // data-msg-required="Zip code is required."
                              type="text"
                              id="edit-authrepzip"
                              // name="authrepzip"
                              // value=""
                              size="60"
                              maxLength="5"
                              data-drupal-states='{"required":[{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"auth_rep"}},"or",{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022has_authrep_info\u0022]":{"checked":true}}]}'
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="authrepzip">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-textfield
                          js-form-type-textfield
                          form-item-authrepphone
                          js-form-item-authrepphone
                          form-group
                        "
                          >
                            <label
                              htmlFor="edit-authrepphone"
                              className="control-label form-required"
                            >
                              Phone number
                            </label>
                            <CSPhoneInput
                                  name="authrepphone"
                                  id="edit-authrepphone"
                                  value={state.enrollData.authrepphone}
                                  onChange={handleChange("authrepphone")}
                                  control={control}
                                  //rules={{ required: "Authorized representative phone number required." }}
                                />
                            {/* <PhoneInput
                              name="authrepphone"
                              value={state.enrollData.authrepphone}
                              onChange={handleAuthRepPhone}
                              country="US"
                              id="authrepphone"
                              control={control}
                              rules={
                                {
                                  // ...(state.enrollData.preparer === "authRep" && {
                                  //   required:
                                  //     "Authorized representative phone number required.",
                                  //   minLength: {
                                  //     value: 14,
                                  //   },
                                  //   maxLength: {
                                  //     value: 14,
                                  //   },
                                  // }),
                                }
                              }
                              defaultValue={state.enrollData.authrepphone}
                              maxLength="14"
                              className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                              style={{ border: "1px solid #ccc" }}
                            /> */}
                            <div className="error">
                              <ErrorMessage errors={errors} name="authrepphone">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div
                            className="
                          form-item
                          js-form-item
                          form-type-checkbox
                          js-form-type-checkbox
                          form-item-authrepmail
                          js-form-item-authrepmail
                          checkbox
                        "
                          >
                            <label
                              htmlFor="edit-authrepmail"
                              className="control-label option"
                            >
                              <input
                                {...register("authrepmail")}
                                onChange={handleChange("authrepmail")}
                                value={state.enrollData.authrepmail}
                                data-drupal-selector="edit-authrepmail"
                                className="form-checkbox"
                                data-msg-required="Please send mail to this address is required."
                                type="checkbox"
                                id="edit-authrepmail"
                                name="authrepmail"
                                //value="1"
                              />
                              Please send mail to this address
                            </label>
                          </div>
                        </div>
                        <div
                          id="edit-agent-note"
                          className="
                        form-item
                        js-form-item
                        form-type-processed-text
                        js-form-type-processed-text
                        form-item-
                        js-form-item-
                        form-no-label form-group
                      "
                          {...(state.enrollData.preparer !== "authRep" && {
                            style: { display: "none" },
                          })}
                        >
                          <p>
                            Note: To enroll an applicant online, you must have
                            their permission. Forms should be submitted within 2
                            calendar days of the date you receive the complete
                            form.
                          </p>
                        </div>
                      </div>

                      <div
                        className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-call-center-info"
                        data-msg-required="Call center information is required."
                        data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"callcenter"}}}'
                        id="edit-call-center-info"
                        //style="display: none"
                        {...(!state.enrollData.callCenterAssist && {
                          style: { display: "none" },
                        })}
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Call center information
                          </div>
                        </div>

                        <div className="panel-body">
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-select
                            js-form-type-select
                            form-item-callcentername
                            js-form-item-callcentername
                            form-group
                          "
                          >
                            <label
                              htmlFor="edit-callcentername"
                              className="control-label"
                            >
                              Call center
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("callcentername")}
                                value={state.enrollData.callcentername}
                                onChange={handleChange("callcentername")}
                                data-drupal-selector="edit-callcentername"
                                className="form-select form-control"
                                data-msg-required="Call center is required."
                                id="edit-callcentername"
                                name="callcentername"
                                data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"callcenter"}}}'
                                defaultValue=""
                              >
                                <option value="">- Select -</option>
                                <option value="CALLCTRDD">DialogDirect</option>
                              </select>
                            </div>
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-textfield
                            js-form-type-textfield
                            form-item-callcenterrep
                            js-form-item-callcenterrep
                            form-group
                          "
                          >
                            <label
                              htmlFor="edit-callcenterrep"
                              className="control-label"
                            >
                              Call center representative (first name last name)
                            </label>

                            <input
                              {...register("callcenterrep")}
                              value={state.enrollData.callcenterrep}
                              onChange={handleChange("callcenterrep")}
                              pattern="[-A-Za-z]+(?:\s+[-A-Za-z]+)+"
                              data-webform-pattern-error="Call center representative (first name last name) should be at least 2 words long."
                              data-drupal-selector="edit-callcenterrep"
                              className="form-text form-control"
                              // data-msg-maxLength="Call center representative (first name last name) field has a maximum length of 25."
                              // data-msg-required="Call center representative (first name last name) is required."
                              // data-msg-pattern="Call center representative (first name last name) should be at least 2 words long."
                              type="text"
                              id="edit-callcenterrep"
                              // name="callcenterrep"
                              // value=""
                              size="60"
                              maxLength="25"
                              data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"callcenter"}}}'
                            />
                          </div>
                          <div
                            className="
                            form-item
                            js-form-item
                            form-type-textfield
                            js-form-type-textfield
                            form-item-ridnbr
                            js-form-item-ridnbr
                            form-group
                          "
                          >
                            <label
                              htmlFor="edit-ridnbr"
                              className="control-label"
                            >
                              Call record number
                            </label>

                            <input
                              {...register("ridnbr", {
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Call record number field has a maximum length of 255.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={state.enrollData.ridnbr}
                              onChange={handleChange("ridnbr")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-ridnbr"
                              className="form-text form-control"
                              // data-msg-maxLength="Call record number field has a maximum length of 255."
                              // data-msg-required="Call record number is required."
                              // data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-ridnbr"
                              // name="ridnbr"
                              // value=""
                              size="60"
                              maxLength="255"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Agent Info--Do not hide */}
                      <div
                      // {...(state.enrollData.preparer !== "agent" && {
                      //   style: { display: "none" },
                      // })}
                      >
                        <div
                          className="
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                          data-drupal-selector="edit-agent-assisted"
                          data-msg-required="If you were assisted by an agent, please fill in information if known is required."
                          data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"!value":"agent"}}}'
                          id="edit-agent-assisted"
                        >
                          <div className="panel-heading">
                            <div className="panel-title collapse-link">
                              If you were assisted by an agent, please fill in
                              information if known
                            </div>
                          </div>

                          <div className="panel-body"></div>
                        </div>
                        <div
                          className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                          data-drupal-selector="edit-agent-information-required"
                          data-msg-required="Agent information (*required)  is required."
                          data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                          id="edit-agent-information-required"
                          //style="display: none"
                        >
                          <div
                            className="panel-heading"
                            {...(state.enrollData.preparer !== "agent" && {
                              style: { display: "none" },
                            })}
                          >
                            <div className="panel-title collapse-link">
                              Agent information (*required)
                            </div>
                          </div>

                          <div className="panel-body"></div>
                        </div>
                        <div
                          className="
                        form-item
                        js-form-item
                        form-type-textfield
                        js-form-type-textfield
                        form-item-agentfirst
                        js-form-item-agentfirst
                        form-group
                      "
                        >
                          <label
                            htmlFor="edit-agentfirst"
                            className={
                              state.enrollData.preparer === "agent"
                                ? "control-label form-required"
                                : "control-label"
                            }
                          >
                            Agent first name
                          </label>

                          <input
                            {...(state.enrollData.preparer === "agent" && {
                              ...register("agent.first", {
                                required: "Agent first name is required.",

                                pattern: {
                                  value: /^[-/a-zA-Z0-9 -]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Agent first name field has a maximum length of 25.",
                                },
                              }),
                            })}
                            value={state.enrollData.agentfirst}
                            onChange={handleChange("agentfirst")}
                            pattern="^[-a-zA-Z0-9 ]*$"
                            data-webform-pattern-error="Special characters are not allowed."
                            data-drupal-selector="edit-agentfirst"
                            className="form-text form-control"
                            // data-msg-maxLength="Agent first name field has a maximum length of 25."
                            // data-msg-required="Agent first name is required."
                            // data-msg-pattern="Special characters are not allowed."
                            type="text"
                            id="edit-agentfirst"
                            // name="agentfirst"
                            // value=""
                            size="60"
                            maxLength="25"
                            data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                          />
                          <div className="error">
                            <ErrorMessage errors={errors} name="agent.first">
                              {({ messages }) =>
                                messages &&
                                Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p key={type}>{message}</p>
                                  )
                                )
                              }
                            </ErrorMessage>
                          </div>
                        </div>
                        <div
                          className="
                        form-item
                        js-form-item
                        form-type-textfield
                        js-form-type-textfield
                        form-item-agentlast
                        js-form-item-agentlast
                        form-group
                      "
                        >
                          <label
                            htmlFor="edit-agentlast"
                            className={
                              state.enrollData.preparer === "agent"
                                ? "control-label form-required"
                                : "control-label"
                            }
                          >
                            Agent last name
                          </label>

                          <input
                            {...register("agent.last", {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    state.enrollData.preparer === "agent"
                                  )
                                    return "Agent last name is required.";
                                  return true;
                                },
                              },
                              pattern: {
                                value: /^[-/a-zA-Z0-9 -]*$/,
                                message: "Special characters are not allowed.",
                              },
                              maxLength: {
                                value: 25,
                                message:
                                  "Agent last name field has a maximum length of 25.",
                              },
                            })}
                            value={state.enrollData.agentlast}
                            onChange={handleChange("agentlast")}
                            pattern="^[-a-zA-Z0-9 ]*$"
                            data-webform-pattern-error="Special characters are not allowed."
                            data-drupal-selector="edit-agentlast"
                            className="form-text form-control"
                            // data-msg-maxLength="Agent last name field has a maximum length of 25."
                            // data-msg-required="Agent last name is required."
                            // data-msg-pattern="Special characters are not allowed."
                            type="text"
                            id="edit-agentlast"
                            // name="agentlast"
                            // value=""
                            size="60"
                            maxLength="25"
                            data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                          />
                          <div className="error">
                            <ErrorMessage errors={errors} name="agent.last">
                              {({ messages }) =>
                                messages &&
                                Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p key={type}>{message}</p>
                                  )
                                )
                              }
                            </ErrorMessage>
                          </div>
                        </div>
                        <div
                          className="
                        form-item
                        js-form-item
                        form-type-textfield
                        js-form-type-textfield
                        form-item-agentid
                        js-form-item-agentid
                        form-group
                      "
                        >
                          <label
                            htmlFor="edit-agentid"
                            className={
                              state.enrollData.preparer === "agent"
                                ? "control-label form-required"
                                : "control-label"
                            }
                          >
                            Agent number
                          </label>

                          <input
                            {...register("agent.id", {
                              validate: {
                                required: (value) => {
                                  if (
                                    !value &&
                                    state.enrollData.preparer === "agent"
                                  )
                                    return "Agent number is required.";
                                  return true;
                                },
                              },
                              pattern: {
                                value: /^[-a-zA-Z0-9 ]*$/,
                                message: "Special characters are not allowed.",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Agent number field has a maximum length of 10.",
                              },
                            })}
                            value={state.enrollData.agentid}
                            onChange={handleChange("agentid")}
                            pattern="^[-a-zA-Z0-9 ]*$"
                            data-webform-pattern-error="Special characters are not allowed."
                            data-drupal-selector="edit-agentid"
                            className="form-text form-control"
                            // data-msg-maxLength="Agent number field has a maximum length of 10."
                            // data-msg-required="Agent number is required."
                            // data-msg-pattern="Special characters are not allowed."
                            type="text"
                            id="edit-agentid"
                            // name="agentid"
                            // value=""
                            size="60"
                            maxLength="10"
                            data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                          />
                          <div className="error">
                            <ErrorMessage errors={errors} name="agent.id">
                              {({ messages }) =>
                                messages &&
                                Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p key={type}>{message}</p>
                                  )
                                )
                              }
                            </ErrorMessage>
                          </div>
                        </div>

                        {/*Hidden, determine if it needs to be displayed*/}
                        <div
                          className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-type-select
                        js-form-type-select
                        form-item-enrollee-perm-state
                        js-form-item-enrollee-perm-state
                        form-group
                      "
                          {...(state.enrollData.preparer !== "agent" && {
                            style: { display: "none" },
                          })}
                        >
                          <label
                            className={
                              state.enrollData.preparer === "agent"
                                ? "control-label form-required"
                                : "control-label"
                            }
                          >
                            Enrollee's permanent state
                          </label>

                          <div className="select-wrapper">
                            <select
                              {...register("permstate", {
                                validate: {
                                  required: (value) => {
                                    if (
                                      !value &&
                                      state.enrollData.preparer === "agent"
                                    )
                                      return "Enrollee's permanent state is required.";
                                    return true;
                                  },
                                },
                              })}
                              value={state.enrollData.permstate}
                              onChange={handleChange("permstate")}
                              data-drupal-selector="edit-enrollee-perm-state"
                              className="form-select form-control"
                              data-msg-required="Enrollee's permanent state is required."
                              id="edit-enrollee-perm-state"
                              name="enrollee_perm_state"
                            >
                              <option value="">- Select -</option>
                              <option value="IA">Iowa</option>
                              <option value="MN">Minnesota</option>
                              <option value="MT">Montana</option>
                              <option value="ND">North Dakota</option>
                              <option value="NE">Nebraska</option>
                              <option value="SD">South Dakota</option>
                              <option value="WY">Wyoming</option>
                            </select>
                            <div className="error">
                              <ErrorMessage errors={errors} name="permstate">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>

                        <div
                          className="
                        form-item
                        js-form-item
                        form-type-textfield
                        js-form-type-textfield
                        form-item-agencyid
                        js-form-item-agencyid
                        form-group
                      "
                        >
                          <label
                            htmlFor="edit-agencyid"
                            className="control-label"
                          >
                            Agency number
                          </label>

                          <input
                            {...register("agencyid", {
                              pattern: {
                                value: /^[-a-zA-Z0-9 ]*$/,
                                message: "Special characters are not allowed.",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Agency number field has a maximum length of 10.",
                              },
                            })}
                            value={state.enrollData.agencyid}
                            onChange={handleChange("agencyid")}
                            pattern="^[-a-zA-Z0-9 ]*$"
                            data-webform-pattern-error="Special characters are not allowed."
                            data-drupal-selector="edit-agencyid"
                            className="form-text form-control"
                            // data-msg-maxLength="Agency number field has a maximum length of 10."
                            // data-msg-required="Agency number is required."
                            // data-msg-pattern="Special characters are not allowed."
                            type="text"
                            id="edit-agencyid"
                            // name="agencyid"
                            // value=""
                            size="60"
                            maxLength="10"
                          />
                          <div className="error">
                            <ErrorMessage errors={errors} name="agencyid">
                              {({ messages }) =>
                                messages &&
                                Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p key={type}>{message}</p>
                                  )
                                )
                              }
                            </ErrorMessage>
                          </div>
                        </div>
                        <div
                          className="
                        form-item
                        js-form-item
                        form-type-textfield
                        js-form-type-textfield
                        form-item-agentphone
                        js-form-item-agentphone
                        form-group
                      "
                        >
                          <label
                            htmlFor="edit-agentphone"
                            className={
                              state.enrollData.preparer === "agent"
                                ? "control-label form-required"
                                : "control-label"
                            }
                          >
                            Agent phone number
                          </label>
                          <br />
                          <CSPhoneInput
                                  name="agentphone"
                                  id="edit-agentphone"
                                  value={state.enrollData.agentphone}
                                  onChange={handleChange("agentphone")}
                                  control={control}
                                  rules={{
                                    validate: {
                                      required: (value) => {
                                        if (
                                          !value &&
                                          state.enrollData.preparer === "agent"
                                        )
                                          return "Agent phone number required.";
                                        return true;
                                      },
                                    }
                                  }}
                                />
                          {/* <PhoneInput
                            name="agentphone"
                            value={state.enrollData.agentphone}
                            onChange={handleAgentPhone}
                            country="US"
                            id="agentphone"
                            control={control}
                            rules={{
                              validate: {
                                required: (value) => {
                                  if (
                                    value === "" &&
                                    state.enrollData.preparer === "agent"
                                  )
                                    return "Agent phone number required.";
                                  return true;
                                },
                              },
                              maxLength: {
                                value: 14,
                              },
                            }}
                            defaultValue={state.enrollData.agentphone}
                            maxLength="14"
                            className="
                                    col-sm-12 col-md-12
                                    form-item
                                    js-form-item
                                    form-type-textfield
                                    js-form-type-textfield
                                    form-item-homephone
                                    js-form-item-homephone
                                    form-group"
                            style={{ border: "1px solid #ccc" }}
                          /> */}
                          <div className="error">
                            <ErrorMessage errors={errors} name="agentphone">
                              {({ messages }) =>
                                messages &&
                                Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p key={type}>{message}</p>
                                  )
                                )
                              }
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      {/* End Agent Info--Do not hide */}
                      <br />
                      <div
                        className="
                        js-webform-states-hidden
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-agent-signatures"
                        data-msg-required="Agent signatures is required."
                        data-drupal-states='{"visible":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                        id="edit-agent-signatures"
                        //style="display: none"
                        {...(state.enrollData.preparer !== "agent" && {
                          style: { display: "none" },
                        })}
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                            Agent signatures
                          </div>
                        </div>

                        <div className="panel-body">
                          <fieldset
                            data-drupal-selector="edit-please-specify-one"
                            aria-describedby="edit-please-specify-one--wrapper--description"
                            className="
                            radios--wrapper
                            fieldgroup
                            form-composite
                            webform-composite-visible-title
                            js-webform-type-radios
                            webform-type-radios
                            js-form-item
                            form-item
                            js-form-wrapper
                            form-wrapper
                          "
                            data-msg-required="Please specify whether enrollee is present or if Agent has paper enrollment (attestation) in hand is required."
                            id="edit-please-specify-one--wrapper"
                            data-drupal-states='{"required":{".webform-submission-mbrx-enroll-2021-node-234-add-form :input[name=\u0022preparer\u0022]":{"value":"agent"}}}'
                          >
                            <legend>
                              <span className="fieldset-legend">
                                Please specify whether enrollee is present or if
                                Agent has paper enrollment (attestation) in hand
                              </span>
                            </legend>
                            <div className="fieldset-wrapper">
                              <div
                                id="edit-please-specify-one"
                                className="
                                js-webform-radios
                                webform-options-display-one-column
                              "
                              >
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-please-specify-one
                                  js-form-item-please-specify-one
                                  radio
                                "
                                >
                                  <label
                                    htmlFor="edit-please-specify-one-present"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handleAgentSignedPaper}
                                      checked={
                                        state.enrollData.agentSignedPaper ===
                                        "present"
                                      }
                                      data-drupal-selector="edit-please-specify-one-present"
                                      aria-describedby="edit-please-specify-one--wrapper--description"
                                      className="form-radio"
                                      data-msg-required="Please specify whether enrollee is present or if Agent has paper enrollment (attestation) in hand is required."
                                      type="radio"
                                      id="edit-please-specify-one-present"
                                      name="please_specify_one"
                                      value="present"
                                    />
                                    Enrollee is present
                                  </label>
                                </div>
                                <div
                                  className="
                                  form-item
                                  js-form-item
                                  form-type-radio
                                  js-form-type-radio
                                  form-item-please-specify-one
                                  js-form-item-please-specify-one
                                  radio
                                "
                                >
                                  <label
                                    htmlFor="edit-please-specify-one-attestation"
                                    className="control-label option"
                                  >
                                    <input
                                      onChange={handleAgentSignedPaper}
                                      checked={
                                        state.enrollData.agentSignedPaper ===
                                        "attestation"
                                      }
                                      data-drupal-selector="edit-please-specify-one-attestation"
                                      aria-describedby="edit-please-specify-one--wrapper--description"
                                      className="form-radio"
                                      data-msg-required="Please specify whether enrollee is present or if Agent has paper enrollment (attestation) in hand is required."
                                      type="radio"
                                      id="edit-please-specify-one-attestation"
                                      name="please_specify_one"
                                      value="attestation"
                                    />
                                    Agent has signed paper enrollment form in
                                    hand
                                  </label>
                                </div>
                              </div>

                              <div className="description">
                                <div
                                  id="edit-please-specify-one--wrapper--description"
                                  className="
                                  webform-element-description
                                  form-group
                                "
                                >
                                  Note: Agents or sales representatives should
                                  submit all enrollment forms within 2 calendar
                                  days of receipt so the plan can meet CMS
                                  processing timeframes.
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <div
                            data-drupal-states='{"visible":{":input[name=\u0022please_specify_one\u0022]":{"value":"attestation"}}}'
                            className="
                            webform-has-field-prefix
                            webform-has-field-suffix
                            js-webform-states-hidden
                            form-inline form-item
                            js-form-item
                            form-type-date
                            js-form-type-date
                            form-item-datesignedpaper
                            js-form-item-datesignedpaper
                            form-group
                          "
                            {...(state.enrollData.agentSignedPaper !==
                              "attestation" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-datesignedpaper"
                              className="control-label"
                            >
                              What date did the enrollee sign the paper
                              enrollment form
                            </label>

                            <div className="input-group">
                              <span className="input-group-addon">
                                <div className="date_wrap_div">
                                  <CSDateInput
                                      name="datesignedpaper"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.agentSignedPaper === "attestation" && !value) return "What date did the enrollee sign the paper enrollment form is required."; return true }
                                        }
                                      }}  
                                      value={state.enrollData.datesignedpaper}
                                      onChange={handleChange("datesignedpaper")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />    
                                  <span className="input-group-addon"></span>
                                </div>
                              </span>
                            </div>
                            <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="datesignedpaper"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                          </div>
                          <div
                            data-drupal-states='{"visible":{":input[name=\u0022please_specify_one\u0022]":{"value":"attestation"}}}'
                            className="
                            webform-has-field-prefix
                            webform-has-field-suffix
                            js-webform-states-hidden
                            form-inline form-item
                            js-form-item
                            form-type-date
                            js-form-type-date
                            form-item-agentsigned
                            js-form-item-agentsigned
                            form-group
                          "
                            {...(state.enrollData.agentSignedPaper !==
                              "attestation" && {
                              style: { display: "none" },
                            })}
                          >
                            <label
                              htmlFor="edit-agentsigned"
                              className="control-label"
                            >
                              What date did you, the agent, sign the paper
                              enrollment form
                            </label>

                            <div className="input-group">
                              <span className="input-group-addon">
                                <div className="date_wrap_div">
                                  <CSDateInput
                                      name="agentsigned"
                                      control={control}
                                      rules={{ 
                                        validate: {
                                          requiredValidation:  value => { if (state.enrollData.agentSignedPaper === "attestation" && !value) return "What date did you, the agent, sign the paper enrollment form is required."; return true }
                                        }
                                      }}                                        
                                      value={state.enrollData.agentsigned}
                                      onChange={handleChange("agentsigned")}
                                      className="form-text
                                      required
                                      form-control"                                             
                                  />    
                                  <span className="input-group-addon"></span>
                                </div>
                              </span>
                            </div>
                            <div className="error">
                                    <ErrorMessage
                                      errors={errors}
                                      name="agentsigned"
                                    >
                                      {({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(
                                          ([type, message]) => (
                                            <p key={type}>{message}</p>
                                          )
                                        )
                                      }
                                    </ErrorMessage>
                                  </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="
                        form-item
                        js-form-item
                        form-wrapper
                        js-form-wrapper
                        panel panel-default
                      "
                        data-drupal-selector="edit-accessible-format"
                        data-msg-required="Answering the following questions is your choice. You can’t be denied coverage because you don’t fill out this section. is required."
                        id="edit-accessible-format"
                      >
                        <div className="panel-heading">
                          <div className="panel-title collapse-link">
                          Answering the following questions is your choice. You can’t be denied coverage because you don’t fill out this section.
                          </div>
                        </div>

                        <div className="panel-body"></div>
                      </div>
                      <fieldset
                        data-drupal-selector="edit-accessibleformatoption"
                        aria-describedby="edit-accessibleformatoption--wrapper--description"
                        className="
                        radios--wrapper
                        fieldgroup
                        form-composite
                        webform-composite-visible-title
                        js-webform-type-radios
                        webform-type-radios
                        js-form-item
                        form-item
                        js-form-wrapper
                        form-wrapper
                      "
                        data-msg-required="Select one if you want us to send you information in an accessible format. is required."
                        id="edit-accessibleformatoption--wrapper"
                      >
                        <legend>
                          <span className="fieldset-legend">
                            Select one if you want us to send you information in
                            an accessible format.
                          </span>
                        </legend>
                        <div className="fieldset-wrapper">
                          <div
                            id="edit-accessibleformatoption"
                            className="
                            js-webform-radios
                            webform-options-display-three-columns
                          "
                          >
                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-accessibleformatoption
                              js-form-item-accessibleformatoption
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-accessibleformatoption-braille"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleAccessibilityFormat}
                                  checked={state.enrollData.braille}
                                  data-drupal-selector="edit-accessibleformatoption-braille"
                                  aria-describedby="edit-accessibleformatoption--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Select one if you want us to send you information in an accessible format. is required."
                                  type="checkbox"
                                  id="edit-accessibleformatoption-braille"
                                  name="braille"
                                  value="braille"
                                />{" "}
                                Braille
                              </label>
                            </div>
                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-accessibleformatoption
                              js-form-item-accessibleformatoption
                              radio
                            "
                            >
                              <label
                                htmlFor="edit-accessibleformatoption-large-print"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleAccessibilityFormat}
                                  checked={state.enrollData.largePrint}
                                  data-drupal-selector="edit-accessibleformatoption-large-print"
                                  aria-describedby="edit-accessibleformatoption--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Select one if you want us to send you information in an accessible format. is required."
                                  type="checkbox"
                                  id="edit-accessibleformatoption-large-print"
                                  name="largePrint"
                                  value="largePrint"
                                />{" "}
                                Large print
                              </label>
                            </div>
                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-accessibleformatoption
                              js-form-item-accessibleformatoption
                              radio
                            "
                            >
                              <label
                                htmlFor="edit-accessibleformatoption-audio-cd"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleAccessibilityFormat}
                                  checked={state.enrollData.cd}
                                  data-drupal-selector="edit-accessibleformatoption-audio-cd"
                                  aria-describedby="edit-accessibleformatoption--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Select one if you want us to send you information in an accessible format. is required."
                                  type="checkbox"
                                  id="edit-accessibleformatoption-audio-cd"
                                  name="cd"
                                  value="cd"
                                />{" "}
                                Audio CD
                              </label>
                            </div>
                          </div>

                          <div className="description">
                            <div
                              id="edit-accessibleformatoption--wrapper--description"
                              className="webform-element-description form-group"
                            >
                              Please contact MedicareBlue Rx at{" "}
                              <strong>
                                <a href="tel:1-866-434-2037">1-866-434-2037</a>
                              </strong>{" "}
                              (TTY:
                              <strong>
                                <a href="tel:711">711</a>
                              </strong>
                              ). if you need information in an accessible format
                              other than what’s listed above. Our office hours
                              are 8 a.m. to 8 p.m., daily, Central and Mountain
                              times.
                            </div>
                          </div>
                        </div>
                      </fieldset>


                      {/* <fieldset
                        data-drupal-selector="edit-ethnicityoptions"
                        aria-describedby="edit-ethnicityoptions--wrapper--description"
                        className="
                        radios--wrapper
                        fieldgroup
                        form-composite
                        webform-composite-visible-title
                        js-webform-type-radios
                        webform-type-radios
                        js-form-item
                        form-item
                        js-form-wrapper
                        form-wrapper
                      "
                        data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                        id="edit-ethnicityoptions--wrapper"
                      >
                        <legend>
                          <span className="fieldset-legend">
                          Are you Hispanic, Latino/a, or Spanish origin? Select all that apply.
                          </span>
                        </legend>
                        <div className="fieldset-wrapper">
                          <div
                            id="edit-ethnicityoptions"
                            className="
                            js-webform-radios
                            webform-options-display-three-columns
                          "
                          >
                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityNA"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityNA}
                                  data-drupal-selector="edit-ethnicityNA"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityNA"
                                  name="ethnicityNA"
                                  value="ethnicityNA"
                                />{" "}
                                No, not of Hispanic, Latino/a, or Spanish origin
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityMX"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityMX}
                                  data-drupal-selector="edit-ethnicityMX"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityMX"
                                  name="ethnicityMX"
                                  value="ethnicityMX"
                                />{" "}
                                Yes, Mexican, Mexican American, Chicano/a
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityPR"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityPR}
                                  data-drupal-selector="edit-ethnicityPR"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityPR"
                                  name="ethnicityPR"
                                  value="ethnicityPR"
                                />{" "}
                                Yes, Puerto Rican
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityCUB"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityCUB}
                                  data-drupal-selector="edit-ethnicityCUB"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityCUB"
                                  name="ethnicityCUB"
                                  value="ethnicityCUB"
                                />{" "}
                                Yes, Cuban
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityOTH"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityOTH}
                                  data-drupal-selector="edit-ethnicityOTH"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityOTH"
                                  name="ethnicityOTH"
                                  value="ethnicityOTH"
                                />{" "}
                                Yes, another Hispanic, Latino/a, or Spanish origin
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-ethnicityoptions
                              js-form-item-ethnicityoptions
                              radio
                            "
                            >
                              <label
                                //htmlFor="edit-ethnicityNR"
                                className="control-label option"
                              >
                                <input
                                  onChange={handleEthnicity}
                                  checked={state.enrollData.ethnicityNR}
                                  data-drupal-selector="edit-ethnicityNR"
                                  aria-describedby="edit-ethnicity--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="Are you Hispanic, Latino/a, or Spanish origin? Select all that apply. is required."
                                  type="checkbox"
                                  id="edit-ethnicityNR"
                                  name="ethnicityNR"
                                  value="ethnicityNR"
                                />{" "}
                                <b>I choose not to answer.</b>
                              </label>
                            </div>

                          </div>

                        </div>
                      </fieldset>

                      <fieldset
                        data-drupal-selector="edit-raceoptions"
                        aria-describedby="edit-raceoptions--wrapper--description"
                        className="
                        radios--wrapper
                        fieldgroup
                        form-composite
                        webform-composite-visible-title
                        js-webform-type-radios
                        webform-type-radios
                        js-form-item
                        form-item
                        js-form-wrapper
                        form-wrapper
                      "
                        data-msg-required="What’s your race? Select all that apply. is required."
                        id="edit-raceoptions--wrapper"
                      >
                        <legend>
                          <span className="fieldset-legend">
                          What’s your race? Select all that apply
                          </span>
                        </legend>
                        <div className="fieldset-wrapper">
                          <div
                            id="edit-raceoptions"
                            className="
                            js-webform-radios
                            webform-options-display-three-columns
                          "
                          >
                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio
                            "
                            >
                              <label                                
                                className="control-label option"
                              >
                                <input
                                  onChange={handleRace}
                                  checked={state.enrollData.race300}
                                  data-drupal-selector="edit-race300"
                                  aria-describedby="edit-race--wrapper--description"
                                  className="form-radio"
                                  data-msg-required="What’s your race? Select all that apply is required."
                                  type="checkbox"
                                  id="edit-race300"
                                  name="race300"
                                  value="race300"
                                />{" "}
                                American Indian or Alaska Native
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race401}
                                    data-drupal-selector="edit-race401"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race401"
                                    name="race401"
                                    value="race401"
                                  />{" "}
                                    Asian Indian
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race201}
                                    data-drupal-selector="edit-race201"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race201"
                                    name="race201"
                                    value="race201"
                                  />{" "}
                                    Black or African American
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race411}
                                    data-drupal-selector="edit-race411"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race411"
                                    name="race411"
                                    value="race411"
                                  />{" "}
                                    Chinese
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race421}
                                    data-drupal-selector="edit-race421"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race421"
                                    name="race421"
                                    value="race421"
                                  />{" "}
                                    Filipino
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race521}
                                    data-drupal-selector="edit-race521"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race521"
                                    name="race521"
                                    value="race521"
                                  />{" "}
                                    Guamanian or Chamorro
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race431}
                                    data-drupal-selector="edit-race431"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race431"
                                    name="race431"
                                    value="race431"
                                  />{" "}
                                    Japanese
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race441}
                                    data-drupal-selector="edit-race441"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race441"
                                    name="race441"
                                    value="race441"
                                  />{" "}
                                    Korean
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race501}
                                    data-drupal-selector="edit-race501"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race501"
                                    name="race501"
                                    value="race501"
                                  />{" "}
                                    Native Hawaiian
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race499}
                                    data-drupal-selector="edit-race499"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race499"
                                    name="race499"
                                    value="race499"
                                  />{" "}
                                    Other Asian
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race599}
                                    data-drupal-selector="edit-race599"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race599"
                                    name="race599"
                                    value="race599"
                                  />{" "}
                                    Other Pacific Islander
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race511}
                                    data-drupal-selector="edit-race511"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race511"
                                    name="race511"
                                    value="race511"
                                  />{" "}
                                    Samoan
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race451}
                                    data-drupal-selector="edit-race451"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race451"
                                    name="race451"
                                    value="race451"
                                  />{" "}
                                    Vietnamese
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race101}
                                    data-drupal-selector="edit-race101"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race101"
                                    name="race101"
                                    value="race101"
                                  />{" "}
                                    White
                              </label>
                            </div>

                            <div
                              className="
                              form-item
                              js-form-item
                              form-type-radio
                              js-form-type-radio
                              form-item-raceoptions
                              js-form-item-raceoptions
                              radio">
                                <label className="control-label option" >
                                  <input
                                    onChange={handleRace}
                                    checked={state.enrollData.race999}
                                    data-drupal-selector="edit-race999"
                                    aria-describedby="edit-race--wrapper--description"
                                    className="form-radio"
                                    data-msg-required="What’s your race? Select all that apply is required."
                                    type="checkbox"
                                    id="edit-race999"
                                    name="race999"
                                    value="race999"
                                  />{" "}
                                    <b>I choose not to answer</b>
                              </label>
                            </div>
                            

                          </div>

                        </div>
                      </fieldset> */}


                    </div>
                    <Button
                      className="
                            webform-button--previous
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                      onClick={handlePrevious}
                      //disabled={p1}
                      {...(p1 && {
                        style: { display: "none" },
                      })}
                    >
                      Previous
                    </Button>

                    <Button
                      className="
                            webform-button--next
                            button
                            js-form-submit
                            form-submit
                            btn-default btn"
                      onClick={handleNextClick}
                    >
                      {activeStep === steps.length - 1
                        ? "Finish"
                        : activeStep === steps.length - 2
                        ? "Submit"
                        : "Continue to " + steps[activeStep + 1].toString()}
                    </Button>
                    <div
                      className="error"
                      {...(displayCheckOptionError !== true && {
                        style: { display: "none" },
                      })}
                    >
                      At least one item must be selected.
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Step5_2023;
