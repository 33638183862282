import React from "react";
import { Controller } from "react-hook-form";
import "../../assets/themes/custom/npamedicare/css/csdateinput.css";
import moment from "moment";


export default function CSDateInput(props) {

    const [changing, setChanging] = React.useState(false);

    const minDateValue = props.min || "1850-01-01";
    const maxDateValue = props.max || "2999-12-31"; 

    return (

        <Controller        
            name={props.name}
            control={props.control}
            //defaultValue={props.defaultValue}
            defaultValue={props.value}
            rules={{...props.rules,
                    validate: {                     
                        ...(props.rules && props.rules.validate),
                        minDateValidation:  value => { if (!changing && value && moment(value).isBefore(minDateValue)) return "Please verify that date is correct."; return true },
                        maxDateValidation:  value => { if (!changing && value && moment(value).isAfter(maxDateValue)) return "Please verify that date is correct."; return true }
                        //minDateValidation:  value => { if (!changing && value && moment(value).isBefore(minDateValue)) return `Must be greater than ${moment(minDateValue).format('M/D/YYYY')}`; return true },
                        //maxDateValidation:  value => { if (!changing && value && moment(value).isAfter(maxDateValue)) return `Must be less than ${moment(maxDateValue).format('M/D/YYYY')}`; return true }
                  }}}
            

            render={({ field: { onChange } }) => {
            return (                  
                <input
                    // over-writable props
                    min={minDateValue}
                    max={maxDateValue}

                    { ...props}   // pass all other props to DatePicker
                    
                    // non over-writable props
                    onChange={(v) => {
                        onChange(v);  // needed for controller's validation to get updated
                        props.onChange(v)   // handle onChange handler passed to the controller
                    }}

                    type="date"
                    value={props.value}
                    className = {'csdateinput ' + props.className}     
                    
                    onFocus={() => setChanging(true)}
                    onBlur={() => setChanging(false)}
                />
            )}}            
        />
    )
}




