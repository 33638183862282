import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const EmployerGroupDocs2022 = () => {
  const [content, isLoading] = useContent("6DVdSDQmTfHC7GTaGF3HmU");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/GroupBenefits">Employer group plans</Link>
                        </li>
                        <li>2022 Employer group documents</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <article className="page full clearfix">
              <span
                property="schema:name"
                content="2020 Employer group documents"
                className="hidden"
              ></span>

              <div className="content">
                <div
                  property="schema:text"
                  className="field field--name-body field--type-text-with-summary field--label-hidden field__item"
                >
                  {/* begin copy/paste */}
                  <div id="docspage">
                    <h1>
                      <ReactMarkdown children={content.title} />
                    </h1>

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead1}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[0].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[0].fields
                              .descriptiveText
                          }
                        />
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[0].fields.documentList
                          }
                        />
                      </li>
                    </ul>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[1].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[1].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[1].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />
                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[2].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[2].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[2].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />
                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[3].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[3].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[3].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />
                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[4].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule[4].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule[4].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead2}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule2[0].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[0].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule2[0].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />
                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule2[1].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule2[1].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule2[1].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead3}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[0].fields.documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[0].fields.descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[0].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[1].fields.documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[1].fields.descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[1].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[2].fields.documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[2].fields.descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[2].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[3].fields.documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[3].fields.descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[3].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[4].fields.documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.docContentModule3[4].fields.descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.docContentModule3[4].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead4}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule4[0].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule4[0].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule4[0].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead5}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule5[0].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentModule5[0].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentModule5[0].fields
                                .documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />

                    <h2>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={content.subhead6}
                      />
                    </h2>

                    <ul className="nobullet">
                      <li>
                        <strong>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentMod6[0].fields
                                .documentTitle
                            }
                          />
                        </strong>
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={
                            content.documentContentMod6[0].fields
                              .descriptiveText
                          }
                        />
                        <div className="pdflist">
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.documentContentMod6[0].fields.documentList
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <br />
                  </div>
                  {/* end copy/paste */}
                </div>

                <div className="field field--name-field-content-section field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div className="paragraph paragraph--type--content-section paragraph--view-mode--default">
                      <div className="field field--name-field-file-or-link field--type-entity-reference-revisions field--label-above">
                        <div className="field__label">File or Link</div>
                        <div className="field__items">
                          <div className="field__item">
                            <div className="paragraph paragraph--type--file-or-link paragraph--view-mode--default"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field field--name-field-add-accordion field--type-entity-reference-revisions field--label-hidden field__items">
                  <div className="field__item">
                    <div id="accordion-54" className="form-group"></div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>

        {/* <aside
          className="col-sm-4 col-md-3 side-section order-md-1"
          role="complementary"
        >
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="first med-group-benefit-solutions">
                <Link to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups">
                  Group benefit solutions
                </Link>
              </li>
              <li className="med-group-resources">
                <Link to="/EmployerGroupPlans/GroupResources">
                  Group resources
                </Link>
              </li>
              <li className="active active-trail med-2020-group-documents">
                <Link
                  to="/EmployerGroupPlans/EmployerGroupDocs2022"
                  className="active-trail is-active"
                >
                  2022 Group documents
                </Link>
              </li>
              <li className="med-group-coverage-tools">
                <Link to="/EmployerGroupPlans/GroupPlanResearchTools">
                  Group coverage tools
                </Link>
              </li>
              <li className="med-group-contact-information">
                <Link to="/employer-group-plans/group-contact-information">
                  Group contact information
                </Link>
              </li>
              <li className="last med-fraud,-waste-and-abuse-information">
                <Link to="/EmployerGroupPlans/FWA">
                  Fraud, waste and abuse information
                </Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default EmployerGroupDocs2022;
