import { handleResponse, handleError } from "./apiUtils";
const url = process.env.REACT_APP_CONFIRM_API_URL;

export async function submitConfirmation(confirmation) {
  try {
    let response = await fetch(url, {
      mode: "cors",
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(confirmation),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
